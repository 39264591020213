/**
 * Section Types
 * - topic landing page
 *   - How To Guides, Admin, optional pdf embed at top
 * - faq accordians
 * - topic switcher
 * - pdf embed
 * - custom (catchall)
 */

/**
 * Sidebars: Each sidebar lists all the pages and headers
 * Pages: Each page is just the page plus a reference to the sidebar
 */

export const MainSidebar = [
  { type: 'link', title: 'How To Guides', href: '/help/info/how-to' },
  { type: 'link', title: 'Frequently Asked Questions', href: '/help/info/faq' },
  { type: 'link', title: 'Admin', href: '/help/info/admin' },
];

export const TopicSidebars: { [path: string]: HelpModule.SidebarItem[] } = {
  'how-to': [
    { type: 'header', title: 'Getting Started Guides' },
    {
      type: 'link',
      title: 'Getting Started Guide v1.19',
      href: '/assets/help/pdfs/Getting Started Guide - CIAO v1.19.pdf',
      icon: 'pdf',
      external: true,
      new: true,
    },
    {
      type: 'link',
      title: 'Volunteer Login Guide',
      href: '/assets/help/pdfs/CIAO Volunteer Login Guide v2 - December 2024.pdf',
      icon: 'pdf',
      external: true,
      new: true,
    },
    {
      type: 'link',
      icon: 'video',
      title: 'How to Log In with a Personal Device',
      href: '/help/info/how-to/login-personal-device',
    },
    {
      type: 'link',
      icon: 'video',
      title: 'How to Log In with a Government Device',
      href: '/help/info/how-to/login-government-device',
    },
    {
      type: 'link',
      title: 'How to Register and Update your User Profile',
      href: '/help/info/how-to/register-user-profile',
    },
    { type: 'header', title: 'Trip Management' },
    {
      type: 'link',
      title: 'How to Be Added to a Team',
      href: '/help/info/how-to/teams-be-added',
    },

    {
      type: 'link',
      title: 'How to Create a Trip ',
      href: '/help/info/how-to/trips-create',
    },
    {
      type: 'link',
      title: 'How to Edit a Trip ',
      href: '/help/info/how-to/trips-edit',
    },
    {
      type: 'link',
      title: "How to Change Trip Status (Check Out, Check In, and I'm Okay)",
      href: '/help/info/how-to/trips-check-in-out-okay',
    },
    {
      type: 'link',
      title: 'How to View a Trip',
      href: '/help/info/how-to/trips-view-log',
    },
    {
      type: 'link',
      title: 'All About Text Notifications',
      href: '/help/info/how-to/text-notifications',
    },
    {
      type: 'link',
      title: 'Quick Links',
      href: '/help/info/how-to/quick-links',
    },
    { type: 'header', title: 'Related FAQs' },
    {
      type: 'link',
      title: 'How do I create a CIAO user account? ',
      href: '/help/info/faq/create-ciao-user',
    },
    {
      type: 'link',
      title: 'Can team members without a USDA account use CIAO? ',
      href: '/help/info/faq/non-usda-team-members',
    },
  ],
  admin: [
    { type: 'header', title: 'Getting Started Guides' },
    {
      type: 'link',
      title: 'Getting Started Guide v1.19',
      href: '/assets/help/pdfs/Getting Started Guide - CIAO v1.19.pdf',
      icon: 'pdf',
      external: true,
      new: true,
    },
    {
      type: 'link',
      title: 'Volunteer Login Guide',
      href: '/assets/help/pdfs/CIAO Volunteer Login Guide v2 - December 2024.pdf',
      icon: 'pdf',
      external: true,
      new: true,
    },

    { type: 'header', title: 'Management' },
    {
      type: 'link',
      icon: 'video',
      title: 'Roles & Permissions',
      href: '/help/info/admin/roles-permissions',
    },
    {
      type: 'link',
      title: 'How to Add, Edit, or Delete a User',
      href: '/help/info/admin/users-edit-delete',
    },

    {
      type: 'link',
      icon: 'video',
      title: 'How to Add, Edit, or Delete a Team',
      href: '/help/info/admin/teams-create-edit-delete',
    },
    { type: 'header', title: 'Related FAQs' },
    {
      type: 'link',
      title: 'How do I create a CIAO user account?',
      href: '/help/info/faq/create-ciao-user',
    },
    {
      type: 'link',
      title: 'Can team members without a USDA account use CIAO?',
      href: '/help/info/faq/non-usda-team-members',
    },
  ],
};

export const HelpPages: { [path: string]: HelpModule.HelpPage } = {
  'how-to': {
    title: 'How To Guides',
    type: 'topic/image',
    src: '/assets/help/img/how-to-landing-splash.png',
    alt: 'Decorative Images of a backpack, a video on a monitor, and a printed KBA file.  Text reads: We all learn in different ways.  Some like to explore, others prefer watching videos, and some just want to print it out and read.  We have you covered!',
    description: '',
    // 'Select a topic from the right menu to watch the video or view and print the KBA.',
  },
  admin: {
    title: 'Admin',
    type: 'topic/image',
    src: '/assets/help/img/admin-landing-splash.svg',
    alt: 'Welcome, Admin!  Decorative images of people.  Example Admin One is a System Administrator.  Example Admin Two is a Region or Station Administrator.  Example Admin Three is a Forest or Lab Administrator.',
    description: '',
    // '## Choose a topic to learn more' +
    // '\n\nWe all learn differently, some want to explore, others want to watch a video, and some want to print it out and read it. We have you covered! Choose a topic to watch the video or view and print the KBA.',
  },
  faq: {
    title: 'Frequently Asked Questions',
    type: 'custom/faq',
  },
  'version-history': {
    title: 'Version History',
    type: 'custom/versionHistory',
  },
  'how-to/login-personal-device': {
    title: 'How to Log In with a Personal Device',
    type: 'topic/video',
    src: '/assets/help/vid/user-training-feb-24.mp4',
    alt: '',
    poster: '/assets/help/vid/login-personal-device-poster.png',
    description:
      '## How to Log In with a Personal Device' +
      '\n\nAccess CIAO from your personal laptop or mobile device using Login.gov to log in. Watch a short video tutorial for an overview of the process.' +
      '\n\n[View How to Login with a Personal Device KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00148926/rkm)',
  },
  'how-to/login-government-device': {
    title: 'How to Log In with a Government Device',
    type: 'topic/video',
    src: '/assets/help/vid/how-to-login-gov.mp4',
    alt: '',
    poster: '/assets/help/vid/how-to-login-gov.png',
    description:
      '## How to Log In with a Government Device' +
      '\n\nAccess CIAO from your Government-issued laptop or mobile device using eAuth to log in. Watch a short video tutorial for an overview of the process. You can view and/or download the latest Knowledge Based Article (KBA) using the link below.' +
      '\n\n[View How to Login with a Government Device KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00142843/rkm)',
  },
  'how-to/register-user-profile': {
    title: 'How to Register and Update your User Profile',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## How to Register and Update your User Profile' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about registering and updating your CIAO user profile on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      '\n\n[View How to Register and Update your User Profile KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00142844/rkm)',
  },
  'how-to/trips-create': {
    title: 'How to Create a Trip ',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## How to Create a Trip' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about creating a trip in CIAO on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      '\n\n[Visit Create a Trip KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00146637/rkm)' +
      '  \n[Visit Duplicate a Trip KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00150725/rkm)',
  },
  'how-to/trips-edit': {
    title: 'How to Edit a Trip ',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## How to Edit a Trip' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about editing, duplicating, or deleting a trip in CIAO on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      '\n\n[Visit Edit a Trip KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00150232/rkm)' +
      '  \n[Visit Delete a Trip KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00150334/rkm)' +
      '  \n[Visit Duplicate a Trip KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00150725/rkm)',
  },
  'how-to/trips-check-in-out-okay': {
    title: "How to Change Trip Status (Check Out, Check In, and I'm Okay)",
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      "## How to Change Trip Status (Check Out, Check In, and I'm Okay)" +
      '\n\nThis How To video is coming soon! In the meantime, learn more about how to check out, check in, and let your team know you’re okay in CIAO on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA). ' +
      '\n\n[View "Check Out, Check In, and I\'m Okay" KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00143129/rkm)',
  },
  'how-to/quick-links': {
    title: 'Quick Links',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## Quick Links' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about using CIAO Quick Links on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA). ' +
      '\n\n[View Quick links KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00155732/rkm)',
  },

  'how-to/trips-view-log': {
    title: 'How to View a Trip',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## How to view a Trip' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about how to view trip status and trip logs in CIAO on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      '\n\n[Visit the Trip Status and Trip Log KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00142840/rkm)' +
      '  \n[Visit the View a List of Planned Trips KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00150233/rkm)',
  },
  'how-to/text-notifications': {
    title: 'All About Text Notifications',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## All About Text Notifications' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about text notifications in CIAO on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      ' \n\n[Visit the Enabling Text Notifications KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00146994/rkm)',
  },

  'how-to/teams-be-added': {
    title: 'How to Be Added to a Team',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## How to Be Added to a Team' +
      '\n\nThis How To video is coming soon! In the meantime, learn more about how to be added to a team in CIAO on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      '\n\n[Visit the View or Be Added to a Team KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00142755/rkm)',
  },
  // 'teams-roles-permissions': {
  //   title: 'Roles and Permissions Matrix',
  //   type: 'topic/video',
  //   src: '',
  //   alt: '',
  //   poster: '',
  //   description:
  //     '[View the Roles & Permissions Matrix in PDF](/assets/help/pdfs/admin-roles-permissions.pdf)' +
  //     '  \n[Visit the KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00146994/rkm)',
  // },

  // 'roles-permissions-kba': {
  //   title: 'Roles & Permissions',
  //   type: 'externalTopic',
  //   href: 'https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00146910/rkm',
  // },
  'admin/roles-permissions': {
    title: 'Roles & Permissions',
    type: 'topic/video',
    src: '/assets/help/vid/admin-roles-permissions-dec-13.mp4',
    alt: '',
    poster: '/assets/help/vid/admin-roles-permissions-poster.png',
    description:
      '## Roles & Permissions' +
      '\n\nLearn the various CIAO roles and permissions for Registered Users and Admins. Watch a short video tutorial for an overview, view the latest Knowledge Based Article (KBA), or download a PDF for easy viewing.' +
      '\n\n[Visit the Roles and Permissions KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00146910/rkm)' +
      '  \n[Visit the PDF for Roles and Permissions](/assets/help/pdfs/admin-roles-permissions.pdf)',
  },
  // 'roles-permissions-accessible': {
  //   title: 'Roles & Permissions',
  //   type: 'topic/pdf',
  //   pdfUrl: 'admin-roles-permissions.pdf',
  // },

  'admin/users-edit-delete': {
    title: 'How to Add, Edit, or Delete a User',
    type: 'topic/video',
    src: '',
    alt: '',
    poster: '/assets/help/vid/video-coming-soon.png',
    description:
      '## How to Add, Edit, or Delete a User' +
      '\n\nAdmins, this How To video is coming soon! In the meantime, learn more about adding, editing, or deleting a CIAO user on the CEC Digital Workplace where you can view and/or download the latest Knowledge Based Article (KBA).' +
      '\n\n[Visit the Add, Edit, or Delete a User KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00147010/rkm)' +
      '  \n[Visit the PDF for Adding a User](/assets/help/pdfs/admin.user.create.pdf)',
  },

  'admin/teams-create-edit-delete': {
    title: 'How to Add, Edit, or Delete a Team',
    type: 'topic/video',
    src: '/assets/help/vid/admin-managing-teams-dec-6.mp4',
    alt: '',
    poster: '/assets/help/vid/add-delete-team-poster.png',
    description:
      '## How to Add, Edit, or Delete a Team' +
      '\n\nAdmins, learn how to add, edit, or delete a team in CIAO. Watch a short video tutorial for an overview, view the latest Knowledge Based Article (KBA), or download a PDF for easy viewing.' +
      '\n\n[Visit the Create, Edit, or Delete a Team KBA](https://usdacts-myit.fed.onbmc.com/dwp/app/#/knowledge/KBA00147429/rkm)',
  },
};
