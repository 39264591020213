import { BooleanInput } from '@angular/cdk/coercion';
import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { CiaoSharedModule } from '~app/components/shared/shared.module';
import { PaginationResult } from '~app/models/pagination-data';

@Component({
  standalone: true,
  imports: [
    CiaoSharedModule,
    CiaoFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
  ],
  selector: 'ciao-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.less'],
})
export class PageSelectorComponent implements OnInit, OnChanges {
  @Input() displayRowCount: BooleanInput;

  @Input() rowCount = 0;
  @Input() totalRowCount = 0;
  @Input() isLoading = false;

  @Input() tableName: string;

  @Input() paginationResult: PaginationResult<any>;

  rowsPerPage = new UntypedFormControl(25);

  @Input() pageControl = new UntypedFormControl(1);

  private _limit: number;
  private _offset: number;

  @Output() paginationDataChange = new EventEmitter<{
    limit: number;
    offset: number;
  }>();

  /**
   * @example searchBox ? "matching '" + searchBox + "'" : ""
   */
  @Input() filteredMessage = '';

  pageCount = 0;
  rowNumbers: number[] = [10, 25, 50, 100];

  faArrowRight = faChevronRight;
  faArrowLeft = faChevronLeft;

  constructor() {}

  ngOnInit(): void {
    this.calculatePages();
    this.handleRowsPerPageChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paginationResult?.currentValue) {
      this.rowCount = this.paginationResult.rowCount;
      this.totalRowCount = this.paginationResult.totalRowCount;
      this.isLoading = this.paginationResult.isLoading;
    }
    if (!this.isLoading) {
      this.calculatePages();
    }
  }

  calculatePages() {
    let newPageCount =
      Math.ceil(this.totalRowCount / this.rowsPerPage.value) || 1;
    if (newPageCount !== this.pageCount) {
      this.pageCount = newPageCount;
    }

    if (this.pageControl.value > this.pageCount) {
      this.pageControl.setValue(this.pageCount);
    }
    if (this.pageControl.value <= 0) {
      this.pageControl.setValue(1);
    }

    this._limit = this.rowsPerPage.value;
    this._offset = this.pageControl.value * this.rowsPerPage.value;

    this.paginationDataChange.emit({
      limit: this.rowsPerPage.value,
      offset: (this.pageControl.value - 1) * this.rowsPerPage.value,
    });
  }

  goToPage(page: number) {
    this.pageControl.setValue(page);
    this.calculatePages();
  }

  handleRowsPerPageChange() {
    this.rowsPerPage.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          this.pageControl.setValue(1);
        })
      )
      .subscribe();
  }
}
