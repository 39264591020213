import { Injectable } from '@angular/core';
import { ApiService, Endpoint } from './api.service';
import {
  Observable,
  MonoTypeOperatorFunction,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, shareReplay, skip, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { UserGroupAttributes } from '~app/models/user-group';
import { AuthService } from './auth.service';
import { PersonAttributes } from '~app/models/person';
import { AssignedRole } from '~app/models/assigned-role';
@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  private endpoint: Endpoint<UserGroupAttributes>;
  public readonly _refreshList$ = new ReplaySubject<void>(1);
  private _userGroupById: { [Id: string]: UserGroupAttributes } = {};

  public readonly op_sortGroups: MonoTypeOperatorFunction<
    UserGroupAttributes[]
  > = (userGroups$) => {
    return userGroups$.pipe(
      tap((userGroups) => {
        userGroups.sort(this.compareForSort);
      })
    );
  };

  public readonly allUserGroups$ = this._refreshList$.pipe(
    switchMap((_) => this.endpoint.GetAll()),
    tap((userGroups) => {
      userGroups.forEach((userGroup) => {
        this._userGroupById[userGroup.id] = userGroup;
      });
      userGroups.forEach((userGroup) => {
        // Moving this filter here to avoid doing this in more numberous places.
        const children = userGroups.filter(
          (child) => child.parentId === userGroup.id
        );
        children.forEach((child) => (child.parent = userGroup));
        // Using Object.defineProperty to prevent it from showing up in JSON stringify
        Object.defineProperty(userGroup, 'children', {
          value: children,
        });
      });
      userGroups.forEach((group) => {
        // examine all parents to set specific info
        let parents = [
          group.parent?.parent?.parent,
          group.parent?.parent,
          group.parent,
        ].filter((p) => p);
        group.regionStation = parents.find((p) => p?.tierLabel === 'Region');
        group.forestLab = parents.find((p) => p?.tierLabel === 'ForestLab');
        let parentPrefix = parents
          .map((p) => p.abbreviation)
          .filter((s) => s)
          .join(' | ');
        if (parentPrefix) {
          parentPrefix += ' | ';
        }
        let ownPrefix = group.abbreviation ? group.abbreviation + ' - ' : '';
        group.labelPrefix = parentPrefix + ownPrefix;
      });
    }),
    shareReplay(1)
  );
  public readonly allUserGroupsById$ = this.allUserGroups$.pipe(
    map((_) => this._userGroupById)
  );

  public readonly allTeams$ = this.allUserGroups$.pipe(
    map((groups) => groups.filter((group) => group.tierLabel === 'Team')),
    this.op_sortGroups,
    shareReplay(1),
    map((groups) => groups.slice())
  );
  // for region dropdown vals
  public readonly allForestLabs$ = this.allUserGroups$.pipe(
    map((groups) => groups.filter((group) => group.tierLabel === 'ForestLab')),
    this.op_sortGroups,
    shareReplay(1),
    map((groups) => groups.slice())
  );
  public readonly allRegions$ = this.allUserGroups$.pipe(
    map((groups) => groups.filter((group) => group.tierLabel === 'Region')),
    this.op_sortGroups,
    shareReplay(1),
    map((userGroups) => userGroups.slice())
  );

  public readonly systemUserGroup$ = this.allUserGroups$.pipe(
    map((groups) => groups.find((group) => group.tier === 0)),
    shareReplay(1)
  );

  public readonly userGroupsByTier$ = combineLatest([
    this.allTeams$,
    this.allForestLabs$,
    this.allRegions$,
    this.systemUserGroup$,
  ]).pipe(
    map(([teams, forests, regions, system]) => ({
      teams,
      forests,
      regions,
      system,
    }))
  );

  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.endpoint = apiService.endpoint('user-groups');

    this.loadAllUserGroups();
  }

  findById(id: string): Observable<UserGroupAttributes> {
    return this.allUserGroups$.pipe(
      map((userGroup: UserGroupAttributes[]) => {
        return userGroup.find((userGroup) => userGroup.id === id);
      })
    );
  }

  get op_FindByIdAfterRefresh() {
    // wrapped inside a get() because `this._refreshList$` has `this` as undefined.
    return (id$: Observable<string>) => {
      return id$.pipe(
        tap((_) => this._refreshList$.next()),
        switchMap((id) =>
          this.allUserGroups$.pipe(
            skip(1),
            map((_) => id)
          )
        ),
        switchMap((id) => this.findById(id))
      );
    };
  }

  compareForSort(g1: UserGroupAttributes, g2: UserGroupAttributes) {
    if (!g1 || !g2) {
      return 0;
    }
    if (g1.parentId === g2.parentId) {
      return g1.sortOrder - g2.sortOrder;
    }
    let g1Group = [g1, g1.parent, g1.parent?.parent, g1.parent?.parent?.parent]
      .filter((x) => !!x)
      .reverse();
    let g2Group = [g2, g2.parent, g2.parent?.parent, g2.parent?.parent?.parent]
      .filter((x) => !!x)
      .reverse();

    // Top-most parent will be gxGroup[0].  Will always be System.
    // [1] will be region.
    // [2] will be Forest/Lab (or tier2 Team) or non-existent
    // [3] will be Team or non-existent
    return (
      (g1Group[1]?.sortOrder || 0) - (g2Group[1]?.sortOrder || 0) ||
      (g1Group[2]?.sortOrder || 0) - (g2Group[2]?.sortOrder || 0) ||
      (g1Group[3]?.sortOrder || 0) - (g2Group[3]?.sortOrder || 0)
    );
  }

  getSystemWhereImAdmin() {
    return this.searchMyUserGroups(['system_admin']);
  }
  getRegionsWhereImRegionAdmin() {
    return this.searchMyUserGroups(['region_admin']);
  }
  getTeamsWhereImSupervisor() {
    return this.searchMyUserGroups(['supervisor']);
  }

  searchMyUserGroups(roleIds: string[]) {
    return this.authService.personId$.pipe(
      switchMap((personId) =>
        // log in here maybe to make sure its not poppin off
        {
          return this.searchUserGroups({
            personIds: [personId],
            roleIds: roleIds,
          });
        }
      )
    );
  }
  searchUserGroups(searchBody: { personIds?: string[]; roleIds?: string[] }) {
    let myGroupsPartial$ = this.apiService
      .Search<UserGroupAttributes[]>('actions/search-user-groups', searchBody)
      .pipe();
    let final$ = combineLatest([
      myGroupsPartial$,
      this.allUserGroupsById$,
    ]).pipe(
      map(([myGroups, allGroupsById]) =>
        myGroups.map((group) => allGroupsById[group.id])
      )
    );
    return final$;
  }

  /**
   * Returns lists of users with the given roles, separated by the given roles.
   * - Should return all users and all their roles.
   * - Should return an empty list for any specified roles that doesn't have a user attached.
   */
  getMembersByRole<T extends string>(
    userGroupId: string,
    roleIds: readonly T[]
  ): Observable<{
    [roleId in T]: PersonAttributes[];
  }> {
    return this.apiService
      .Get<(PersonAttributes & { AssignedRoles: AssignedRole[] })[]>(
        '/actions/user-group-member-list/',
        userGroupId
      )
      .pipe(
        map((people) => {
          const lists = {} as { [roleId: string]: PersonAttributes[] };
          people.forEach((person) => {
            person.AssignedRoles.forEach((role) => {
              lists[role.RoleId] = lists[role.RoleId] ?? [];
              if (person) {
                lists[role.RoleId].push(person);
              }
            });
          });
          return lists;
        }),
        map((response) => {
          roleIds?.forEach((id) => (response[id] = response[id] ?? []));
          // Wish I didn't have to return this with an `as`.
          return response as { [roleId in T]: PersonAttributes[] };
        })
      );
  }

  /**
   * Return dropdown lists for Crew Member and Supervisor.
   * - Allow Supervisors to be an option in the Crew Member dropdown list.
   * - Reusable across different trip forms
   */
  getCrewMemberDropdownLists(userGroupId: string) {
    return this.getMembersByRole(userGroupId, [
      'team_member',
      'supervisor',
    ]).pipe(
      map((listsByRole) => {
        let supervisorsMinusMembers = listsByRole.supervisor.filter(
          (sup) => !listsByRole.team_member.find((mem) => mem.id === sup.id)
        );
        let crewMembers = [
          ...listsByRole.team_member,
          ...supervisorsMinusMembers,
        ];

        return {
          crew_member: crewMembers
            .map((person) => ({
              value: person,
              label: person.displayNameDropdown,
            }))
            .sort((a, b) =>
              a.label > b.label ? 1 : a.label < b.label ? -1 : 0
            ),
          supervisor: listsByRole.supervisor
            .map((person) => ({
              value: person,
              label: person.displayNameDropdown,
            }))
            .sort((a, b) =>
              a.label > b.label ? 1 : a.label < b.label ? -1 : 0
            ),
        };
      })
    );
  }

  loadAllUserGroups() {
    this._refreshList$.next();
    return this.allUserGroups$.pipe(skip(1));
  }

  addUserGroup(input: UserGroupAttributes): Observable<UserGroupAttributes> {
    let request = this.endpoint.Post(input);
    return request.pipe(
      map((group: UserGroupAttributes) => group.id),
      this.op_FindByIdAfterRefresh
    );
  }

  saveUserGroup(input: UserGroupAttributes): Observable<UserGroupAttributes> {
    let request: Observable<UserGroupAttributes>;
    if (input.id) {
      request = this.endpoint.Put(input.id, input);
    } else {
      request = this.endpoint.Post(input);
    }
    return request.pipe(
      map((group) => group.id),
      this.op_FindByIdAfterRefresh
    );
  }

  deleteUserGroup(id: uuid): Observable<void> {
    let request = this.endpoint.Delete(id);
    return request.pipe(
      tap((_) => this._refreshList$.next()),
      map((_) => null)
    );
  }
}
