import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

/**
 * Don't make the same `(item)=>item.id` method on every component where you have a for-loop using item.id ...
 * Use this instead.
 */
@Directive({
  standalone: true,
  selector: '[ngForTrackById]',
})
export class NgForTrackByIdDirective<T extends { id: string | number }> {
  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (index: number, item: T) => item.id;
  }
}
