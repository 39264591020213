<div class="content">
  <ciao-user-profile-form
    [personId]="personId$ | async"
  ></ciao-user-profile-form>
  <hr />
  <h1 id="my-teams-table-label">My Teams</h1>
  <ciao-user-profile-teams-table
    [personId]="personId$ | async"
  ></ciao-user-profile-teams-table>
</div>
