<div class="content">
  <h2 id="teams-table-identifier" class="title">Teams</h2>
  <div class="interactables-row">
    <span class="search-and-filter">
      <ciao-form-field
        label="Search"
        [formControlInput]="searchTextControl"
      ></ciao-form-field>
      <button
        *ngIf="true"
        class="btn-action-solid filter-btn"
        (click)="filterModal.openModal()"
      >
        <fa-icon [icon]="filter"></fa-icon> Filter
      </button>
    </span>
    <button
      *ngIf="canCreateTeam$ | async"
      class="new-item-button semantic-button"
      (click)="userGroupModal.openModal(null, 'Add')"
    >
      New Team
      <span class="icon" role="presentation">
        <img src="assets/img/icons/new-item-button-icon.svg" alt="Go" />
      </span>
    </button>
  </div>
  <ciao-page-selector
    [displayRowCount]="true"
    [tableName]="'Teams'"
    [paginationResult]="paginationResult$ | async"
  ></ciao-page-selector>
  <div class="scroll-x-on-overflow">
    <table
      ciao-table-style-v1
      [attr.aria-describedby]="'teams-table-identifier'"
    >
      <thead>
        <tr>
          <th>Team Name</th>
          <th>Region or Station</th>
          <th>Forest or Lab</th>
          <th>Contact</th>
          <th>Contact Email</th>
        </tr>
      </thead>
      <tbody *ngIf="paginationResult$ | async as paginationResult">
        <ng-template [ngIf]="paginationResult.isLoading">
          <tr class="messageRow">
            <td [attr.colspan]="6">
              <ciao-loading></ciao-loading>
            </td>
          </tr>
        </ng-template>
        <ng-template
          ngFor
          let-team
          [ngForOf]="paginationResult?.rows"
          let-i="index"
          [ngForTrackById]
        >
          <tr (click)="userGroupModal.openModal(team, 'Edit')">
            <td>{{ team.labelPrefix }}{{ team.label }}</td>
            <td>
              {{ team.regionStation?.label }}
            </td>
            <td>
              {{ team.forestLab?.label }}
            </td>
            <td>
              {{ team.pointOfContact }}
            </td>
            <td>
              {{ team.contactEmail }}
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <ciao-page-selector
    class="pageSelector"
    (paginationDataChange)="pagingInfo$.next($event)"
    [tableName]="'Teams'"
    [paginationResult]="paginationResult$ | async"
    [filteredMessage]="paginationMessage"
  ></ciao-page-selector>
</div>

<ciao-user-group-modal #userGroupModal></ciao-user-group-modal>

<ciao-filter-modal
  #filterModal
  (applyFilters)="applyFilters$.next()"
></ciao-filter-modal>
