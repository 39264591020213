<app-fs-header></app-fs-header>
<header>
  <a [routerLink]="'/home'" routerLinkActive="active-link">
    <img
      class="ciao-logo-full"
      src="assets/img/ciao/ciao-logo-brown-full.svg"
      alt="ciao logo"
    />
    <img
      class="ciao-logo"
      src="assets/img/ciao/ciao-logo-brown.svg"
      alt="ciao logo"
    />
  </a>
  <ng-container *ngIf="currentUser$ | async as user; else notLoggedInNameplate">
    <span class="login-info">
      <a [routerLink]="'/my-profile'" class="display-name semantic-button">
        {{ user.person.displayName }}
      </a>

      <button
        class="signout-button btn-action-solid"
        (click)="authService.logout()"
      >
        Sign Out
      </button>
    </span>
  </ng-container>
  <ng-template #notLoggedInNameplate>
    <span class="login-info">Not Logged In</span>
  </ng-template>
</header>

<nav class="navbar">
  <a
    class="ciao-logo-reversed"
    [routerLink]="'/home'"
    routerLinkActive="active-link"
  >
    <span class="screenreader">Home</span>
  </a>
  <a class="user-profile-mobile" [routerLink]="'/my-profile'">
    <span class="screenreader">My Profile</span>
  </a>
  <button class="signout semantic-button" (click)="authService.logout()">
    Sign Out
  </button>

  <button class="semantic-button menu-btn" #menubtn id="menu-btn">
    <span class="bar"> </span>
    <span class="bar"> </span>
    <span class="bar"> </span>
  </button>

  <div class="navbar-links">
    <a class="home" [routerLink]="'/home'" routerLinkActive="active-link">
      <span class="screenreader">Home</span>
    </a>

    <ng-template ngFor let-link [ngForOf]="activeRouterLinks$ | async">
      <a [routerLink]="link.url" routerLinkActive="active-link">
        {{ link.title }}
      </a>
    </ng-template>
  </div>
</nav>

<div class="mobile-nav navbar-links" #links>
  <li>
    <a
      class="home"
      (click)="removeActive()"
      [routerLink]="'/home'"
      routerLinkActive="active-link"
    >
      <span class="screenreader">Home</span>
    </a>
  </li>
  <ng-template ngFor let-link [ngForOf]="activeRouterLinks$ | async">
    <li>
      <a
        [routerLink]="link.url"
        (click)="removeActive()"
        class="link"
        routerLinkActive="active-link"
      >
        {{ link.title }}
      </a>
    </li>
  </ng-template>
</div>

<main class="content" role="main">
  <ciao-loading *ngIf="!routerOutlet.isActivated"></ciao-loading>
  <router-outlet #routerOutlet="outlet"></router-outlet>
  <ciao-user-modal
    #userModal
    [id]="(this.currentUser$ | async)?.personId"
  ></ciao-user-modal>
</main>

<div class="footer-container">
  <app-fs-footer></app-fs-footer>
</div>

<ciao-timeout-signout></ciao-timeout-signout>
