import { Component, Input, Output, ViewChild } from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { FormControl, FormGroup, FormRecord } from '@angular/forms';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pairwise,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators';

import { UserGroupAttributes } from '~app/models/user-group';
import { AuthService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';
import { CiaoSharedModule } from '../shared.module';
import { CiaoFormsModule } from '../forms/forms.module';
import { CiaoModalComponent } from '../ciao-modal/ciao-modal.component';

type GroupOptions = {
  label: string;
  id: UserGroupAttributes['id'];
  parentId: UserGroupAttributes['parentId'];
  userGroupObject: UserGroupAttributes;
  children?: GroupOptions[];
};

@Component({
  selector: 'ciao-filter-modal',
  standalone: true,
  imports: [CiaoSharedModule, CiaoFormsModule, NgIf, NgFor, AsyncPipe],
  templateUrl: './filter-modal.component.html',
  styleUrl: './filter-modal.component.less',
})
export class FilterModalComponent {
  @ViewChild('filterModal') modal: CiaoModalComponent;
  @Input() includeMyUserGroupsCheckbox: boolean;
  @Input() includeRolesFilters: boolean;
  currentUser$ = this.authService.currentUser$;

  searchTextControl = new FormControl('');
  continuousFilterGroup = new FormGroup({
    searchText: this.searchTextControl,
  });

  filterModalControls = {
    roles: new FormGroup({
      system_admin: new FormControl(false),
      region_admin: new FormControl(false),
      forest_admin: new FormControl(false),
      supervisor: new FormControl(false),
      team_member: new FormControl(false),
    }),
    myUserGroups: new FormControl(false),

    /** filled in dynamically */
    regions: new FormRecord({}),
    /** filled in dynamically */
    forests: new FormRecord({}),
    /** The set of checkboxes that control "All" the other checkboxes in their group */
    allChecks: new FormRecord({}),
  };
  filterModalGroup = new FormGroup(this.filterModalControls);
  filterModalInitialized = false;
  readonly filterRegionList$ = this.initializeFilterModalRegionOptions();
  @Output('applyFilters')
  readonly applyFilters$ = new BehaviorSubject<void>(null);

  myUserGroups$ = this.userGroupService.searchMyUserGroups([
    'team_member',
    'supervisor',
    'region_admin',
  ]);
  /** For reseting the filters, ie: when you close the modal without applying */
  previousFilterData: typeof this.filterModalGroup.value;

  get filterShouldShowForestLab() {
    if (!this.includeRolesFilters) {
      return true;
    }
    let { forest_admin, team_member, supervisor } =
      this.filterModalGroup.value.roles;
    return Boolean(forest_admin || team_member || supervisor);
  }
  get filterShouldShowRegionStation() {
    if (!this.includeRolesFilters) {
      return true;
    }
    let { region_admin, forest_admin, team_member, supervisor } =
      this.filterModalGroup.value.roles;
    return Boolean(region_admin || forest_admin || team_member || supervisor);
  }

  private allSubscriptions: Subscription = new Subscription();

  constructor(
    private userGroupService: UserGroupService,
    private authService: AuthService
  ) {
    this.allSubscriptions.add(this.filterRegionList$.pipe(take(1)).subscribe());
  }

  openModal() {
    this.modal.openModal();
  }

  clearFilters() {
    this.filterModalGroup.reset();
    this.applyFilters$.next();
    this.previousFilterData = this.filterModalGroup.value;
  }
  applyFilters() {
    this.previousFilterData = this.filterModalGroup.value;
    this.applyFilters$.next();
    this.filterModalGroup.markAsPristine();
  }
  resetFilters() {
    this.filterModalGroup.patchValue(this.previousFilterData);
  }

  initializeFilterModalRegionOptions() {
    this.filterModalInitialized = false;
    return combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
      this.userGroupService.allTeams$,
    ]).pipe(
      take(1),
      map(([system, regions, forests, teams]) => ({
        system,
        regions,
        forests,
        teams,
      })),
      map(({ system, regions, forests, teams }) => {
        let regionOptions = regions.map((region) => {
          let forestOptions = region.children
            .filter((forest) => forest.tierLabel === 'ForestLab')
            .map((forest) => {
              return {
                label: forest.labelPrefix + forest.label,
                id: forest.id,
                parentId: forest.parentId,
                userGroupObject: forest,
              };
            });
          return {
            label: region.labelPrefix + region.label,
            id: region.id,
            parentId: region.parentId,
            children: forestOptions,
            userGroupObject: region,
          };
        });

        return regionOptions as GroupOptions[];
      }),
      tap((regionOptions) => {
        this.filterModalControls.regions = new FormGroup({});
        this.filterModalControls.forests = new FormGroup({});
        this.filterModalControls.allChecks = new FormGroup({});
        this.filterModalControls.allChecks.addControl(
          'regions',
          new FormControl()
        );
        regionOptions.forEach((region) => {
          this.filterModalControls.regions.addControl(
            region.id,
            new FormControl()
          );
          this.filterModalControls.allChecks.addControl(
            region.id,
            new FormControl()
          );
          region.children.forEach((forest) => {
            this.filterModalControls.forests.addControl(
              forest.id,
              new FormControl()
            );
          });
        });
        this.filterModalGroup = new FormGroup(this.filterModalControls);
        this.clearFilters();
      }),
      tap((regionOptions) => {
        this.initializeFilterModalBehaviors(regionOptions);
      }),
      tap(() => {
        this.filterModalInitialized = true;
      }),
      shareReplay(1)
    );
  }

  initializeFilterModalBehaviors(regionOptions: GroupOptions[]) {
    // Checking the regions - "All" checkbox should check all the regions.
    // Checking one of the forests - "All" checkbox should check all forests in that region.
    // Unchecking regions "All" when it is checked should uncheck all regions.
    // Unchecking one of the forests - "All" boxes should uncheck all forests in that region.
    // Unchecking one of the regions should uncheck the regions - "All" checkbox
    // Unchecking one of the forests should uncheck the "All" checkbox for that region.
    // Manually checking the last region  should automatically check or uncheck the Regions - "All" checkbox.
    // Manually checking the forest in a group should automatically check or uncheck the All Forests checkbox for that region.

    // Tried to use `{emitEvent: false}`, but that didn't work. Because we can't compare with unemitted events.
    // Instead, using skipChanges and skipping changes that occur while skipChanges is true.

    const forestOptions = regionOptions.map((region) => region.children).flat();
    const groupOptionsById = Object.fromEntries(
      [...regionOptions, ...forestOptions].map((option) => [option.id, option])
    ) as { [id: string]: GroupOptions };

    let skipChanges = false;

    // Make "All" checkboxes check and uncheck boxes in their group
    this.allSubscriptions.add(
      this.filterModalControls.allChecks.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            let groupId = Object.keys(newValues).find(
              (k) => newValues[k] !== oldValues[k]
            );
            return { groupId, value: newValues[groupId] };
          }),
          filter(({ groupId, value }) => !!groupId),
          // End Find Changed Key
          filter((_) => !skipChanges),
          map(({ groupId, value }) => {
            let list = regionOptions;
            let control = this.filterModalControls.regions;
            if (groupId !== 'regions') {
              list = groupOptionsById[groupId].children;
              control = this.filterModalControls.forests;
            }

            skipChanges = true;
            control.patchValue(
              Object.fromEntries(list.map((group) => [group.id, value]))
            );
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );

    // Check and Uncheck
    this.allSubscriptions.add(
      this.filterModalControls.regions.valueChanges
        .pipe(
          map((regions) => Object.entries(regions).every((entry) => entry[1])),
          filter((_) => !skipChanges),
          tap((allRegionsChecked) => {
            skipChanges = true;
            this.filterModalControls.allChecks.patchValue({
              regions: allRegionsChecked,
            });
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );
    this.allSubscriptions.add(
      this.filterModalControls.forests.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            let forestId = Object.keys(newValues).find(
              (k) => newValues[k] !== oldValues[k]
            );
            return forestId;
          }),
          filter((forestId) => !!forestId),
          // End Find Changed Key
          filter((_) => !skipChanges),
          tap((forestId) => {
            // Find region that this forest is in.
            // Check all forests in that region to see if they're checked or not.
            const forestData = this.filterModalControls.forests.value;
            const regionId = groupOptionsById[forestId].parentId;
            const forestIds = groupOptionsById[regionId].children.map(
              (forest) => forest.id
            );
            const allForestsChecked = forestIds.every(
              (forestId) => forestData[forestId]
            );
            skipChanges = true;
            this.filterModalControls.allChecks.patchValue({
              [regionId]: allForestsChecked,
            });
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );

    // If a region is unchecked, then mark all children as false.
    // Do this regardless of whether it's in response to one of the other automated behaviors
    this.allSubscriptions.add(
      this.filterModalControls.regions.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            return Object.keys(newValues)
              .filter((k) => newValues[k] !== oldValues[k])
              .map((regionId) => ({ regionId, value: newValues[regionId] }));
          }),
          mergeMap((regions, index) => of(...regions)),
          // regionId is truthy and value is falsey
          filter(({ regionId, value }) => !!regionId && !value),
          // End Find Changed Key
          tap(({ regionId, value }) => {
            let patchValue = Object.fromEntries(
              groupOptionsById[regionId].children.map((child) => [
                child.id,
                false,
              ])
            );
            this.filterModalControls.allChecks.patchValue({
              [regionId]: false,
            });
            this.filterModalControls.forests.patchValue(patchValue);
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );
  }
}
