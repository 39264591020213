import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { share, tap } from 'rxjs/operators';

import { repeatLatestWhen } from '~app/utilities/repeat-latest-when.operator';
import { Announcement } from '~app/models/announcement';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  _dismissedItems$ = new BehaviorSubject<void>(null);
  get dismissedIds() {
    let stored = window.sessionStorage.getItem('dismissedAnnouncements');
    return (stored || '').split(',');
  }
  set dismissedIds(value: string[]) {
    window.sessionStorage.setItem('dismissedAnnouncements', value.join(','));
    this._dismissedItems$.next();
  }

  readonly _announcements$ = this.apiService
    .GetAll<Announcement>('announcements')
    .pipe(
      repeatLatestWhen(this._dismissedItems$),
      tap((announcements) => {
        let dismissedIds = this.dismissedIds;
        announcements.forEach((a) => {
          a.dismissed = dismissedIds.includes(a.id);
        });
      })
    );

  /** Each time you run the statement `announcementService.Announcements$`, it will return a new observable */
  get Announcements$() {
    return this._announcements$.pipe(share());
  }

  constructor(private readonly apiService: ApiService) {}

  Dismiss(announcement: Announcement) {
    let ids = this.dismissedIds;
    ids.push(announcement.id);
    this.dismissedIds = ids;
    announcement.dismissed = true;
  }
}
