<ciao-modal #viewAndEditModal title="{{ modalTitle$ | async }}">
  <div modalBody>
    <span *ngIf="selectedTeamPermissions$ | async as teamPermissions">
      <ciao-user-group-form
        #actionUserGroupForm
        [isEditForm]="isEditForm"
        [canEditUserGroup]="teamPermissions.edit || !isEditForm"
        [canEditTeamMembers]="teamPermissions.assignTeamMember || !isEditForm"
        [canEditSupervisors]="teamPermissions.assignSupervisor || !isEditForm"
        [canViewTeamMembers]="teamPermissions.viewTeamMembers || !isEditForm"
      ></ciao-user-group-form>
    </span>
  </div>

  <span modalFooter>
    <div class="d-flex">
      <button
        class="btn-action-light center"
        (click)="viewAndEditModal.dismiss('cancel')"
      >
        Cancel
      </button>
      <button
        *ngIf="isEditForm == false; else editSaveButton"
        class="btn-action-solid center"
        (click)="saveData()"
        [disabled]="!actionUserGroupForm.allowSave"
      >
        Confirm
      </button>
      <ng-template #editSaveButton>
        <button
          *ngIf="(selectedTeamPermissions$ | async)?.clickUpdate"
          class="btn-action-solid center"
          (click)="saveData()"
          [disabled]="!actionUserGroupForm.allowSave"
        >
          Update
        </button>
        <button
          *ngIf="(selectedTeamPermissions$ | async)?.remove"
          class="btn-action-light center"
          (click)="deleteUserGroupModal.openModal()"
        >
          Delete
        </button>
      </ng-template>
    </div>
  </span>
</ciao-modal>

<ciao-modal #deleteUserGroupModal title="Confirm Team Deletion">
  <div modalBody>
    <h2>
      Are you sure you want to delete <br />
      {{ userGroup?.parent?.label }} - {{ userGroup?.label }}?
    </h2>
  </div>
  <span modalFooter>
    <div class="d-flex">
      <button
        class="btn-action-light center"
        (click)="deleteUserGroupModal.dismiss('cancel')"
      >
        Cancel
      </button>
      <button class="btn-action-solid center" (click)="deleteData()">
        Confirm Delete
      </button>
    </div>
  </span>
</ciao-modal>
