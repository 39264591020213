<ciao-page-selector
  [displayRowCount]="true"
  [tableName]="'teams'"
  [paginationResult]="paginationResult$ | async"
></ciao-page-selector>
<table ciao-table-style-v1 aria-describedby="my-teams-table-label">
  <thead>
    <tr>
      <th>Team Name</th>
      <th>Region or Station</th>
      <th>Forest or Lab</th>
      <th>Roles</th>
      <th>User Type</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngIf="paginationResult$ | async as paginationResult">
    <ng-template [ngIf]="paginationResult.isLoading">
      <tr class="messageRow">
        <td [attr.colspan]="6">
          <ciao-loading></ciao-loading>
        </td>
      </tr>
    </ng-template>
    <ng-template
      ngFor
      let-row
      [ngForOf]="paginationResult?.rows"
      let-i="index"
      [ngForTrackById]
    >
      <tr>
        <td>
          <button
            class="semantic-button expand-team-btn"
            (click)="expand(row.team.id)"
          >
            <mat-icon>{{
              row.expanded() ? "expand_more" : "chevron_right"
            }}</mat-icon>
            {{ row.team.label }}
          </button>
        </td>
        <td>{{ row.team.regionStation?.label }}</td>
        <td>{{ row.team.forestLab?.label }}</td>
        <td>{{ row.members.length }} member(s)</td>
        <td></td>
        <td class="row-options-menu-button">
          <button
            mat-icon-button
            [matMenuTriggerFor]="rowOptionsMenu"
            [matMenuTriggerData]="{ team: row.team }"
            aria-label="Row Options"
            class="semantic-button"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </tr>
      <ng-template [ngIf]="row.expanded()">
        <tr *ngFor="let member of row.members">
          <td class="member-display-name">{{ member.person?.displayName }}</td>
          <td></td>
          <td></td>
          <td>{{ member.maxRole }}</td>
          <td>{{ member.person?.jobTitle }}</td>
          <td></td>
        </tr>
      </ng-template>
    </ng-template>
  </tbody>
</table>
<ciao-page-selector
  class="pageSelector"
  (paginationDataChange)="pagingInfo$.next($event)"
  [tableName]="'Teams'"
  [paginationResult]="paginationResult$ | async"
></ciao-page-selector>

<mat-menu #rowOptionsMenu>
  <ng-template matMenuContent let-team="team">
    <button mat-menu-item (click)="openTeamModal(team)">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
  </ng-template>
</mat-menu>
<ciao-user-group-modal #userGroupModal></ciao-user-group-modal>
