// @angular Libraries come first
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgModule,
  APP_INITIALIZER,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { mapTo, tap } from 'rxjs/operators';

// Then libraries maintained outside of @angular
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';

// MaterialModule may be grouped together internally, but it's just a barrel of external Material Modules
import { MaterialModule } from './components/shared/material.module';

import { CiaoSharedModule } from './components/shared/shared.module';
import { CiaoFormsModule } from './components/shared/forms/forms.module';
import { TripModalV2Module } from './components/views/trip/trip-modal-v2/trip-modal-v2.module';

// End of External Libararies

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  LandingComponent,
  AdminComponent,
  SearchComponent,
  ErrorPageComponent,
  StyleGuideComponent,
  PeoplePageComponent,
} from '~/components/views';
import { ScheduledActionDashboardComponent } from './components/views/dev/scheduled-action-dashboard/scheduled-action-dashboard.component';

import { ConfigService, AuthService } from './services';

import { PopupGetStartedComponent } from './components/views/landing/popup-get-started/popup-get-started.component';
import { LoadingDelayPopupComponent } from './components/loading-delay-popup/loading-delay-popup.component';

import { PeopleTableComponent } from './components/views/admin/people-page/people-table/people-table.component';
import { PageSelectorComponent } from './components/report-table/page-selector/page-selector.component';
import { FsHeaderComponent } from './components/fs-header/fs-header.component';
import { FsFooterComponent } from './components/fs-footer/fs-footer.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';

import { StyleGuideFormControlsComponent } from './components/views/style-guide/style-guide-form-controls/style-guide-form-controls.component';
import { SendEmailButtonComponent } from './components/views/search/send-email-button/send-email-button.component';
import { OkayModalComponent } from './components/shared/okay-modal/okay-modal.component';
import { UserGroupPageComponent } from './components/views/user-group-page/user-group-page.component';
import { UserGroupTableComponent } from './components/views/user-group-page/user-group-table/user-group-table.component';
import { RegistrationComponent } from './components/views/registration/registration.component';
import { UserModalComponent } from './components/views/user-modal/user-modal.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { TripSummaryComponent } from './components/views/trip/trip-summary/trip-summary.component';
import { NgForTrackByIdDirective } from './utilities/ng-for-track-by-id.directive';
import { SortableHeaderComponent } from './components/report-table/sortable-header/sortable-header.component';
import { ComboboxTesterComponent } from './components/views/style-guide/style-guide-form-controls/combobox-tester/combobox-tester.component';
import { HelpComponent } from './components/views/help/help.component';
import { InfoComponent } from './components/views/help/info/info.component';
import { FaqsComponent } from './components/views/help/info/faqs/faqs.component';
import { MarkedOptionsFactory } from './markdown-options.factory';
import { VersionHistoryComponent } from './components/views/help/info/version-history/version-history.component';
import { AnnouncementBarComponent } from './components/shared/announcement-bar/announcement-bar.component';
import { TableAdjustmentsDirective } from './directives/table-adjustments.directive';
import { EasterEggService } from './services/easterEgg.service';
import { TimeoutSignoutComponent } from './components/timeout-signout/timeout-signout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

function initializeApplication(
  config: ConfigService,
  authService: AuthService,
  easterEggService: EasterEggService,
  library: FaIconLibrary
): () => Promise<boolean> {
  return () => {
    let authObs = () => authService.loadCurrentUser();
    let obs = config.configsLoaded$.pipe(
      tap({ complete: authObs }),
      mapTo(true)
    );
    return obs.toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    PopupGetStartedComponent,
    LoadingDelayPopupComponent,
    AdminComponent,
    ScheduledActionDashboardComponent,
    SearchComponent,
    ErrorPageComponent,
    StyleGuideComponent,
    PeoplePageComponent,
    FsHeaderComponent,
    FsFooterComponent,
    CopyToClipboardDirective,
    TripSummaryComponent,
    StyleGuideFormControlsComponent,
    SendEmailButtonComponent,
    OkayModalComponent,
    UserGroupPageComponent,
    ComboboxTesterComponent,
    HelpComponent,
    InfoComponent,
    FaqsComponent,
    VersionHistoryComponent,
    TableAdjustmentsDirective,
    TimeoutSignoutComponent,
    OkayModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: MarkedOptionsFactory,
      },
    }),
    CiaoFormsModule,
    CiaoSharedModule,
    NgForTrackByIdDirective,
    TripModalV2Module,
    UserModalComponent,
    RegistrationComponent,
    AnnouncementBarComponent,
    UserGroupTableComponent,
    PeopleTableComponent,
    PageSelectorComponent,
    SortableHeaderComponent,
  ],
  providers: [
    importProvidersFrom([BrowserAnimationsModule]),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [ConfigService, AuthService, EasterEggService, FaIconLibrary],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: navigator?.language ?? 'en-US',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
