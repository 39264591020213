import { Component, OnDestroy, ViewChild, Input } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  share,
  shareReplay,
  startWith,
  switchMapTo,
} from 'rxjs/operators';
import * as _ from 'lodash-es';

import { UserGroupAttributes } from '~app/models/user-group';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';

import { UserGroupService } from '~app/services/user-group.service';
import { AuthService } from '~app/services';
import { UserGroupFormComponent } from '../user-group-form/user-group-form.component';
import { PersonAttributes } from '~app/models';
import { RolePermissionService } from '~app/services/role-permission.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SortingCriteria } from '~app/components/report-table/types';
import { AppliedPermission } from '~app/models/applied-permission';
import { PaginationResult } from '~app/models/pagination-data';
import { SortByCriteria } from '~app/components/report-table/client-side-pagination.functions';
import { BooleanInput } from '@angular/cdk/coercion';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { CiaoSharedModule } from '~app/components/shared/shared.module';
import { FilterModalComponent } from '~app/components/shared/filter-modal/filter-modal.component';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { PageSelectorComponent } from '~app/components/report-table/page-selector/page-selector.component';
import { UserGroupModalComponent } from '../user-group-modal/user-group-modal.component';

@Component({
  standalone: true,
  imports: [
    CiaoSharedModule,
    CiaoFormsModule,
    NgIf,
    NgFor,
    AsyncPipe,
    PageSelectorComponent,
    FilterModalComponent,
    UserGroupModalComponent,
  ],
  selector: 'ciao-user-group-table',
  templateUrl: './user-group-table.component.html',
  styleUrls: ['./user-group-table.component.less'],
})
export class UserGroupTableComponent implements OnDestroy {
  @Input() allowFocusChange: BooleanInput;
  @ViewChild('userGroupModal') userGroupModal: UserGroupModalComponent;
  @ViewChild('filterModal') filterModal: FilterModalComponent;

  //lone control to filter report table
  searchTextControl = new FormControl('');

  continuousFilterGroup = new FormGroup({
    searchText: this.searchTextControl,
  });

  readonly applyFilters$ = new BehaviorSubject<void>(null);

  myUserGroups$ = this.userGroupService.searchMyUserGroups([
    'team_member',
    'supervisor',
    'region_admin',
  ]);
  /** For reseting the filters, ie: when you close the modal without applying */
  previousFilterData: any;

  readonly filterData$ = combineLatest([
    this.continuousFilterGroup.valueChanges.pipe(
      startWith(this.continuousFilterGroup.value),
      debounceTime(500),
      distinctUntilChanged((x, y) => _.isEqual(x, y))
    ),
    this.applyFilters$,
  ]).pipe(switchMapTo(this.getFilterDataObservableLogic()));

  selectedData: UserGroupAttributes & {
    supervisors?: PersonAttributes[];
    teamMembers?: PersonAttributes[];
  };
  selectedTeamPermissions$ = of({
    edit: false,
    remove: false,
    assignTeamMember: false,
    assignSupervisor: false,
    viewTeamMembers: false,
    /** This is a combination of edit or assign team member or assign supervisor */
    clickUpdate: false,
  });
  canCreateTeam$: Observable<boolean> = of(false);
  currentUser$ = this.authService.currentUser$;

  isEditForm: boolean = true;
  modalTitle$: Observable<string> = of('View Team');

  sortingBy$: BehaviorSubject<SortingCriteria[]> = new BehaviorSubject([
    { sortId: 'regionStation.sortOrder', order: 'ASC' },

    { sortId: 'forestLab.sortOrder', order: 'ASC' },

    { sortId: 'label', order: 'ASC' },
  ]);
  pagingInfo$ = new BehaviorSubject({ limit: 0, offset: 0 });

  get paginationMessage() {
    return this.searchTextControl.value
      ? `matching '${this.searchTextControl.value}'`
      : '';
  }

  //#region ClientSideSorting
  // Todo: Replace with ServerSide ?
  sourceData$: Observable<(UserGroupAttributes & { compareString: string })[]> =
    this.userGroupService.allTeams$.pipe(
      map((groups) =>
        groups.map((group) => {
          group.compareString = [
            group.label,
            group.forestLab?.label,
            group.regionStation?.label,
            group.abbreviation,
            group.forestLab?.abbreviation,
            group.regionStation?.abbreviation,
            group.pointOfContact,
            group.contactEmail,
          ]
            .join(' ')
            .toLowerCase();
          return group as UserGroupAttributes & { compareString: string };
        })
      ),
      map((groups) => groups.slice()),
      share()
    );

  paginationResult$: Observable<PaginationResult<UserGroupAttributes>> =
    combineLatest([
      this.sourceData$,
      this.filterData$,
      this.myUserGroups$,
      this.sortingBy$,
      this.pagingInfo$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
    ]).pipe(
      map(([data, filterData, myUserGroups, sortBy, pagingInfo]) => {
        let searchTerms = filterData.filters.searchText
          .toLowerCase()
          .split(' ') as string[];

        let resultData = data.filter((row) =>
          searchTerms.every((term) => row.compareString.includes(term))
        );

        if (filterData.filters.userGroups.length > 0) {
          resultData = resultData.filter((row) =>
            filterData.filters.userGroups.includes(row.id)
          ); // Filter user groups that are ins ide the selected teams
        }

        // This was a bit pre mature but the logic is here for when the issue is ready to be worked on #1229
        // if (filterData.filters.myUserGroups) {
        //   resultData = resultData.filter((row) => {
        //     return myUserGroups.every((team) => team.id === row.id);
        //   });
        // }

        SortByCriteria(resultData, sortBy);
        let pagedData = resultData.slice(
          pagingInfo.offset,
          pagingInfo.offset + pagingInfo.limit
        );
        if (filterData.errors.noUserGroupsMatch) {
          return {
            totalRowCount: 0,
            rowCount: 0,
            rows: [],
            isLoading: false,
          };
        }
        return {
          rows: pagedData,
          rowCount: pagedData.length,
          totalRowCount: resultData.length,
        };
      }),
      share()
    );
  //#endregion

  private allSubscriptions: Subscription = new Subscription();

  // font awesome
  filter = faFilter;

  constructor(
    private userGroupService: UserGroupService,
    private authService: AuthService,
    private rolePermissionService: RolePermissionService
  ) {}

  ngOnInit(): void {
    this.canCreateTeam$ = this.rolePermissionService
      .searchMyAppliedPermissions({
        permissionIds: ['create_child_usergroup'],
      })
      .pipe(
        map((perms) => perms.length > 0),
        share()
      );
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  getFilterDataObservableLogic() {
    return this.userGroupService.userGroupsByTier$.pipe(
      map((allGroups) => {
        const modalData = this.filterModal.filterModalGroup.value;

        const filterData = {
          filters: {
            myUserGroups: modalData.myUserGroups,
            searchText: this.searchTextControl.value,
            userGroups: [],
          },
          errors: {
            noUserGroupsMatch: false,
          },
        };
        /** Calculations that are used multiple times (or in multiple iterations of one loop) */
        const modalDataProcessed = {
          /**
           * Use this to determine if at least one forest inside a region selection is selected.
           * For the purpose of selecting all the forests in a region.
           */
          regionsPartiallySelected: Object.fromEntries(
            allGroups.regions.map((region) => [
              region.id,
              region.children.some((forest) => modalData.forests[forest.id]),
            ])
          ),
          someGroupSelectionMade:
            allGroups.regions.some((region) => modalData.regions[region.id]) ||
            allGroups.forests.some((forest) => modalData.forests[forest.id]),
        };

        if (modalData) {
          if (modalDataProcessed.someGroupSelectionMade) {
            // Add UserGroups, but only if group selections were made.
            filterData.filters.userGroups.push(
              ...allGroups.teams
                .filter(
                  (team) =>
                    modalData.forests[team.forestLab?.id] ||
                    (modalData.regions[team.regionStation?.id] &&
                      !modalDataProcessed.regionsPartiallySelected[
                        team.regionStation?.id
                      ])
                )
                .map((region) => region.id)
            );
          }
        }

        if (
          filterData.filters.userGroups.length === 0 &&
          modalDataProcessed.someGroupSelectionMade
        ) {
          filterData.errors.noUserGroupsMatch = true;
        }

        return filterData;
      })
    );
  }
}
