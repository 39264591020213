import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  share,
  shareReplay,
  startWith,
  tap,
} from 'rxjs/operators';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';
import { AppliedPermission } from '~app/models';
import { UserGroupAttributes } from '~app/models/user-group';
import { RolePermissionService } from '~app/services/role-permission.service';
import { UserGroupFormComponent } from '../user-group-form/user-group-form.component';
import { CiaoSharedModule } from '~app/components/shared/shared.module';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'ciao-user-group-modal',
  standalone: true,
  imports: [AsyncPipe, NgIf, CiaoSharedModule, UserGroupFormComponent],
  templateUrl: './user-group-modal.component.html',
  styleUrl: './user-group-modal.component.less',
})
export class UserGroupModalComponent {
  constructor(
    private readonly rolePermissionService: RolePermissionService,
    private readonly toastrService: ToastrService,
    private readonly cdr: ChangeDetectorRef
  ) {}
  @ViewChild('viewAndEditModal') viewAndEditModal: CiaoModalComponent;
  @ViewChild('actionUserGroupForm') actionUserGroupForm: UserGroupFormComponent;
  @ViewChild('deleteUserGroupModal') deleteUserGroupModal: CiaoModalComponent;
  @ViewChild('viewTeamModal') viewTeamModal: CiaoModalComponent;
  isEditForm: boolean;
  modalTitle$: Observable<string>;
  canCreateTeam$ = this.getCanCreateTeam$();
  selectedTeamPermissions$: ReturnType<
    UserGroupModalComponent['getTeamEditPermissions']
  >;
  userGroup: UserGroupAttributes;

  openModal(userGroup: UserGroupAttributes, action: 'Add' | 'Edit') {
    this.userGroup = userGroup;
    if (action === 'Add') {
      this.isEditForm = false;
      this.modalTitle$ = of('Add Team');
      this.canCreateTeam$ = this.getCanCreateTeam$();
    } else {
      this.isEditForm = true;
      this.selectedTeamPermissions$ = this.getTeamEditPermissions(userGroup);
      this.modalTitle$ = this.selectedTeamPermissions$.pipe(
        map(
          (perms) =>
            perms.edit || perms.assignSupervisor || perms.assignTeamMember
        ),
        map((canEdit) => (canEdit ? 'Edit Team' : 'View Team')),
        startWith('Loading')
      );
    }
    this.cdr.detectChanges();
    let modalRef = this.viewAndEditModal.openModal();
    this.actionUserGroupForm.refreshUserGroup(userGroup.id);
    modalRef.result.then(
      (message) => {
        this.userGroup = null;
      },
      (message) => {
        this.userGroup = null;
      }
    );
  }

  openDeleteUserGroupModal(userGroup) {
    this.deleteUserGroupModal.openModal();
    this.userGroup = userGroup;
  }

  getTeamEditPermissions(userGroup: UserGroupAttributes) {
    return this.rolePermissionService
      .searchMyAppliedPermissions({
        permissionIds: [
          'edit_usergroup',
          'delete_usergroup',
          'assign_team_member',
          'assign_supervisor',
          'view_team_members',
        ],
        filterWithinUserGroups: [userGroup],
      })
      .pipe(
        startWith([] as AppliedPermission[]),
        map((perms) => perms.map((perm) => perm.AppliedPermissionId)),
        map((permIds) => ({
          edit: permIds.indexOf('edit_usergroup') !== -1,
          remove: permIds.indexOf('delete_usergroup') !== -1,
          assignTeamMember: permIds.indexOf('assign_team_member') !== -1,
          assignSupervisor: permIds.indexOf('assign_supervisor') !== -1,
          viewTeamMembers: permIds.indexOf('view_team_members') !== -1,
        })),
        map(
          ({
            edit,
            remove,
            assignTeamMember,
            assignSupervisor,
            viewTeamMembers,
          }) => ({
            edit,
            remove,
            assignTeamMember,
            assignSupervisor,
            viewTeamMembers,
            clickUpdate: edit || assignSupervisor || assignTeamMember,
          })
        ),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  getCanCreateTeam$() {
    return this.rolePermissionService
      .searchMyAppliedPermissions({
        permissionIds: ['create_child_usergroup'],
      })
      .pipe(
        map((perms) => perms.length > 0),
        share()
      );
  }

  saveData() {
    this.actionUserGroupForm
      .saveFormData()
      .pipe(
        tap(() => {
          this.isEditForm
            ? this.toastrService.success(
                'Team was updated successfully.',
                'Success'
              )
            : this.toastrService.success(
                'Team was added successfully',
                'Success'
              );
        }),
        catchError((err, caught) => {
          console.error(err);
          if (this.isEditForm) {
            this.toastrService.error(
              `Team was not updated successfully. Error: ${err}`,
              'Error'
            );
          } else {
            this.toastrService.error(
              `Team was not saved successfully. Error: ${err}`,
              'Error'
            );
          }
          return of(null);
        }),

        tap(() => this.viewAndEditModal.close('Team sucessfully edited'))
      )
      .subscribe();
  }

  deleteData() {
    this.actionUserGroupForm
      .deleteData()
      .pipe(
        tap(() => {
          return this.toastrService.success(
            'Team was deleted successfully.',
            'Success'
          );
        }),
        catchError((err) => {
          console.error(err);
          return of(
            this.toastrService.error(
              `Team was not deleted successfully. Error: ${err}`,
              'Error'
            )
          );
        }),
        tap(() => this.deleteUserGroupModal.close('Team succesfully deleted')),
        tap(() => this.viewAndEditModal.close('Team succesfully deleted'))
      )
      .subscribe();
  }
}
