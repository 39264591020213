import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  share,
  startWith,
  switchMap,
  switchMapTo,
} from 'rxjs/operators';
import { BooleanInput } from '@angular/cdk/coercion';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash-es';

import { PersonAttributes } from '~app/models';
import {
  IsLoadingPaginationResult,
  PaginationData,
  PaginationResult,
} from '~app/models/pagination-data';
import {
  SortingCriteria,
  sortingCriteriaToPaginationSortOrder,
} from '~app/components/report-table/types';
import { PersonService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';
import { UserModalComponent } from '~app/components/views/user-modal/user-modal.component';
import { FilterModalComponent } from '~app/components/shared/filter-modal/filter-modal.component';
import { CiaoSharedModule } from '~app/components/shared/shared.module';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { PageSelectorComponent } from '~app/components/report-table/page-selector/page-selector.component';
import { SortableHeaderComponent } from '~app/components/report-table/sortable-header/sortable-header.component';

@Component({
  standalone: true,
  imports: [
    CiaoSharedModule,
    CiaoFormsModule,
    NgIf,
    NgFor,
    AsyncPipe,
    PageSelectorComponent,
    FilterModalComponent,
    UserModalComponent,
    SortableHeaderComponent,
  ],
  selector: 'people-table',
  templateUrl: './people-table.component.html',
  styleUrls: ['./people-table.component.less'],
})
export class PeopleTableComponent implements OnInit, OnDestroy {
  @Input() allowFocusChange: BooleanInput;

  @ViewChild('userModal') userModal: UserModalComponent;
  @ViewChild('filterModal') filterModal: FilterModalComponent;

  selectedData: PersonAttributes;
  personId: string = null;

  //#region TableStuff
  //lone control to filter report table
  searchTextControl = new FormControl('');
  continuousFilterGroup = new FormGroup({
    searchText: this.searchTextControl,
  });

  readonly applyFilters$ = new BehaviorSubject<void>(null);
  /** For reseting the filters, ie: when you close the modal without applying */
  previousFilterData: any;

  //font awesome'
  filter = faFilter;

  readonly filterData$ = combineLatest([
    this.continuousFilterGroup.valueChanges.pipe(
      startWith(this.continuousFilterGroup.value),
      debounceTime(500),
      distinctUntilChanged((x, y) => _.isEqual(x, y))
    ),
    this.applyFilters$,
  ]).pipe(switchMapTo(this.getFilterDataObservableLogic()));

  sortingBy$ = new BehaviorSubject<SortingCriteria<PersonAttributes>[]>([
    { sortId: 'firstName', order: 'ASC' },
  ]);
  pagingInfo$ = new BehaviorSubject({ limit: 10, offset: 0 });
  get paginationMessage() {
    return this.searchTextControl.value
      ? `matching '${this.searchTextControl.value}'`
      : '';
  }

  paginationResult$: Observable<PaginationResult<PersonAttributes>> =
    combineLatest([
      this.filterData$,
      this.sortingBy$,
      this.pagingInfo$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
    ]).pipe(
      // Normally, I don't like to have this much logic in a single operator
      // But I don't know how to make it actually work without nesting all of this.
      switchMap(([filterData, sortBy, paging]) => {
        if (filterData.errors.noUserGroupsMatch) {
          return of({
            totalRowCount: 0,
            rowCount: 0,
            rows: [],
            isLoading: false,
          }).pipe(
            delay(200),
            startWith(IsLoadingPaginationResult<PersonAttributes>())
          );
        }

        let order = sortingCriteriaToPaginationSortOrder(sortBy);
        let where = Object.entries(filterData.filters).filter(
          (item) => !!item[1]
        );
        let paginationData: PaginationData = {
          limit: paging.limit,
          offset: paging.offset,
          order,
          where,
        };

        return this.personService.search(paginationData).pipe(
          catchError((err: HttpErrorResponse, caught) => {
            console.error(err);
            this.toastrService.error(
              err.error.errors?.toString() || err.message,
              'Users Not Loaded'
            );
            return of({
              totalRowCount: -1,
              rowCount: -1,
              rows: [],
              isLoading: true,
            });
          })
        );
      }),
      share()
    );

  private allSubscriptions: Subscription = new Subscription();
  constructor(
    private personService: PersonService,
    public userGroupService: UserGroupService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  toggleExpandedRows(
    person,
    toggleChoice: 'expand' | 'collapse' | 'toggle',
    $event?: MouseEvent
  ) {
    switch (toggleChoice) {
      case 'toggle':
        if (this.selectedData?.id === person?.id) {
          this.selectedData = null;
        } else {
          this.selectedData = person;
        }
        break;
      case 'expand':
        this.selectedData = person;
        break;
      case 'collapse':
        this.selectedData = null;
        break;
    }
    $event?.stopPropagation();
  }

  /** This Method is used to gather the appropriate roles and permissions given the person id. This could be used later on in the admin tab if applicable. */
  // openViewPersonModal(person: PersonAttributes) {
  //   this.selectedData = person;

  //   const sub = this.rolePermissionService
  //     .searchRoles({
  //       personIds: [this.selectedData.id],
  //       roleIds: ['system_admin', 'region_admin'],
  //     })
  //     .pipe(
  //       tap((assignedRoles) => {
  //         this.selectedData.regionRoles = assignedRoles;
  //       })
  //     )
  //     .subscribe();
  //   this.allSubscriptions.add(sub);

  //   this.viewPersonModal.openSmallModal();
  // }

  openUserModal(person) {
    this.personId = person?.id ?? null;
    this.userModal?.openModal();
  }

  getFilterDataObservableLogic() {
    return this.userGroupService.userGroupsByTier$.pipe(
      map((allGroups) => {
        const modalData = this.filterModal.filterModalGroup.value;
        const filterData = {
          filters: {
            searchText: this.searchTextControl.value,
            userGroups: [],
            roles: [],
          },
          errors: {
            noUserGroupsMatch: false,
          },
        };
        /** Calculations that are used multiple times (or in multiple iterations of one loop) */
        const modalDataProcessed = {
          /**
           * Use this to determine if at least one forest inside a region selection is selected.
           * For the purpose of selecting all the forests in a region.
           */
          regionsPartiallySelected: Object.fromEntries(
            allGroups.regions.map((region) => [
              region.id,
              region.children.some((forest) => modalData.forests[forest.id]),
            ])
          ),
          someGroupSelectionMade:
            (allGroups.regions.some((region) => modalData.regions[region.id]) &&
              this.filterModal.filterShouldShowRegionStation) ||
            (allGroups.forests.some((forest) => modalData.forests[forest.id]) &&
              this.filterModal.filterShouldShowForestLab),
        };

        // Add Roles
        {
          if (modalData.roles.system_admin) {
            filterData.filters.roles.push('system_admin');
          }
          if (modalData.roles.region_admin || modalData.roles.forest_admin) {
            // Due to a previous decision, the role associated with Forest Admin is the same role as Region Admin.
            // So both Region Admin and Forest Admin are 'region_admin' in the database
            filterData.filters.roles.push('region_admin');
          }
          if (modalData.roles.supervisor) {
            filterData.filters.roles.push('supervisor');
          }
          if (modalData.roles.team_member) {
            filterData.filters.roles.push('team_member');
          }
        }

        // Add UserGroups, but only if group selections were made.
        if (modalDataProcessed.someGroupSelectionMade) {
          if (modalData.roles.system_admin) {
            filterData.filters.userGroups.push(allGroups.system.id);
          }
          if (modalData.roles.region_admin) {
            filterData.filters.userGroups.push(
              ...allGroups.regions
                .filter((region) => modalData.regions[region.id])
                .map((region) => region.id)
            );
          }
          if (modalData.roles.forest_admin) {
            filterData.filters.userGroups.push(
              ...allGroups.forests
                .filter(
                  (forest) =>
                    // Add all selected forests
                    modalData.forests[forest.id] ||
                    // Add all forests for each selected region that has no forests selected
                    (modalData.regions[forest.regionStation?.id] &&
                      !modalDataProcessed.regionsPartiallySelected[
                        forest.regionStation?.id
                      ])
                )
                .map((forest) => forest.id)
            );
          }
          if (modalData.roles.supervisor || modalData.roles.team_member) {
            filterData.filters.userGroups.push(
              ...allGroups.teams
                .filter(
                  (team) =>
                    // Add all teams for each forest selected
                    modalData.forests[team.forestLab?.id] ||
                    // Add all teams for each forest in each selected region that has no forests selected
                    (modalData.regions[team.regionStation?.id] &&
                      !modalDataProcessed.regionsPartiallySelected[
                        team.regionStation?.id
                      ])
                )
                .map((region) => region.id)
            );
          }
        } else {
          // No Selection Made, so no groups sent and searching all
          // If region or forest admin and not region admin AND forest admin...
          //   ... Then we should send groups to not search both
          // Due to a previous decision, the role associated with Forest Admin is the same role as Region Admin.
          // So both Region Admin and Forest Admin are 'region_admin' in the database
          let {
            system_admin,
            region_admin,
            forest_admin,
            team_member,
            supervisor,
          } = modalData.roles;
          if (forest_admin !== region_admin) {
            if (forest_admin) {
              filterData.filters.userGroups.push(
                ...allGroups.forests.map((forest) => forest.id)
              );
            } else if (region_admin) {
              filterData.filters.userGroups.push(
                ...allGroups.regions.map((region) => region.id)
              );
            }
            if (team_member || supervisor) {
              filterData.filters.userGroups.push(
                ...allGroups.teams.map((team) => team.id)
              );
            }
            if (system_admin) {
              filterData.filters.userGroups.push(allGroups.system.id);
            }
          }
        }

        if (
          filterData.filters.userGroups.length === 0 &&
          modalDataProcessed.someGroupSelectionMade
        ) {
          filterData.errors.noUserGroupsMatch = true;
        }

        return filterData;
      })
    );
  }

  isSelected(person: PersonAttributes) {
    return this.selectedData?.id === person.id;
  }
}
