import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, of, timer } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { Announcement } from '~app/models/announcement';
import { AnnouncementService } from '~app/services/announcement.service';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { NgIf } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  imports: [NgIf, FontAwesomeModule],
  selector: 'ciao-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.less'],
})
export class AnnouncementBarComponent implements OnInit {
  announcements$: Observable<Announcement[]> =
    this.announcementService.Announcements$;
  announcements: Announcement[] = [];
  announcement$: Observable<Announcement>;
  showAnnouncements = true;
  showTab = false;

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faTimes = faTimes;

  announcementLength = 0;
  currentIndex = 0;
  readonly timerRunning$ = new BehaviorSubject(true);
  readonly timerIncrement$: Observable<void>;

  subscriptions = new Subscription();

  constructor(private readonly announcementService: AnnouncementService) {
    // 5 seconds is too fast.  Use 10 seconds for easier reading
    const timeToRead = 10000;
    this.timerIncrement$ = this.timerRunning$.pipe(
      switchMap((running) => (running ? timer(timeToRead, timeToRead) : of())),
      tap(() => this.clickNext(false)),
      mapTo(null)
    );
    this.refreshDependencies();
  }

  ngOnInit(): void {
    this.refreshDependencies();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  refreshDependencies() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.announcements$
        .pipe(
          tap((announcements) => {
            this.announcements = announcements;
            this.checkIndex();
          })
        )
        .subscribe()
    );
    this.subscriptions.add(this.timerIncrement$.subscribe());
    this.currentIndex = 0;
  }

  clickNext(pause: boolean) {
    this.currentIndex++;
    this.checkIndex();
    if (pause) {
      this.timerRunning$.next(false);
    }
  }
  clickPrevious(pause: boolean) {
    this.currentIndex--;
    this.checkIndex();
    if (pause) {
      this.timerRunning$.next(false);
    }
  }

  checkIndex() {
    if (this.currentIndex < 0) {
      this.currentIndex = this.announcements.length - 1;
    }
    if (this.currentIndex >= this.announcements.length) {
      this.currentIndex = 0;
    }
  }

  openAnnouncementBar() {
    this.timerRunning$.next(true);
    this.showAnnouncements = true;
    this.refreshDependencies();
  }

  closeAnnouncementBar() {
    this.timerRunning$.next(false);
    this.showAnnouncements = false;
  }

  //currently not going to be used but might be useful later so leaving it
  dismissAnnouncement(announcement) {
    this.announcementService.Dismiss(announcement);
  }
}
