import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Routes,
  RouterModule,
  UrlMatchResult,
  UrlSegment,
  UrlMatcher,
  Router,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { filter, tap } from 'rxjs/operators';

import { AuthGuard } from './guards/auth.guard';
import { IsAdminGuard } from './guards/is-admin.guard';
import {
  LandingComponent,
  SearchComponent,
  ErrorPageComponent,
  StyleGuideComponent,
  PeoplePageComponent,
} from '~components/views';
import { ScheduledActionDashboardComponent } from './components/views/dev/scheduled-action-dashboard/scheduled-action-dashboard.component';
import { UserGroupPageComponent } from './components/views/user-group-page/user-group-page.component';
import { RegistrationComponent } from './components/views/registration/registration.component';
import { TripSummaryComponent } from './components/views/trip/trip-summary/trip-summary.component';
import { HelpComponent } from './components/views/help/help.component';
import { InfoComponent } from './components/views/help/info/info.component';
import { UserProfilePageComponent } from './components/views/user-profile-page/user-profile-page.component';

/**
 * This matcher will take routes like /some-slug and /nested/path/some-slug
 *     and return all of them as a single path positional parameter 'slugs'
 *     (to avoid having to match multiple parameters).
 * Unfortunately, we can only assign a singular string as a path parameter, not an array of strings.
 *   So the param looks like `{slugString: 'nested/path/some-slug'}`
 *
 * Goals:
 * - On navigation, Avoid mixing strategies. Either grab a new component instance or use the same one for all paths.
 * - Url should not have unfamiliar symbols in it, (avoid /ciao/help/info;article=how-to,create,teams)
 * - Url should be readable and indicate a nested structure.  (avoid /ciao/help/info/how-to-trips-create )
 * - All child parts of the url should be available to the component.
 *
 * Technicals:
 * - having multiple route configs will cause the component to reload only when changing between Route Configs (like `{path: 'info/:slug1'}` and `{path: 'info/:slug1/:slug2'}`)
 *   - This mixes strategies for loading a new instance or keeping the same instance.
 * - You can only pass UrlSegments to UrlMatchResult.posParams, and route.params[x] can only be strings.
 *
 * @type {UrlMatcher}
 * @returns {{consumed: UrlSegment[], posParams: {slugString: string}}} posParams.slugs is a string like 'my-slug-1,other-slug-2'
 */
export function MultipleSlugMatcher(segments: UrlSegment[]) {
  let slugsArr = segments.map((segment) => segment.path);
  let slugsStr = slugsArr.join('/');
  return {
    consumed: segments,
    posParams: { slugString: new UrlSegment(slugsStr, {}) },
  } as UrlMatchResult;
}

const routes: Routes = [
  {
    path: 'register',
    data: { title: 'Register', showPage: 'intro-question' },
    component: RegistrationComponent,
  },
  {
    path: 'register/new-user',
    data: { title: 'Register', showPage: 'new-user' },
    component: RegistrationComponent,
  },
  {
    path: 'register/existing-user',
    data: { title: 'Register', showPage: 'existing-user' },
    component: RegistrationComponent,
  },
  {
    path: 'error',
    data: { title: 'Error' },
    component: ErrorPageComponent,
  },
  {
    path: 'dev',
    children: [
      {
        path: 'styleGuide',
        data: { title: 'Developer - Style Guide' },
        component: StyleGuideComponent,
      },
      {
        path: 'styleGuide/:focusOn',
        data: { title: 'Developer - Style Guide' },
        component: StyleGuideComponent,
      },
      {
        pathMatch: 'full',
        path: 'scheduled-actions',
        data: { title: 'Developer - Scheduled Actions' },
        component: ScheduledActionDashboardComponent,
        canActivate: [IsAdminGuard],
      },
    ],
  },
  {
    path: 'system-admin',
    loadChildren: () =>
      import('./components/views/system-admin/system-admin.module').then(
        (m) => m.SystemAdminModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        pathMatch: 'full',
        path: '',
        redirectTo: 'home',
      },
      {
        pathMatch: 'full',
        path: 'home',
        data: { title: 'Home' },
        component: LandingComponent,
      },
      {
        pathMatch: 'full',
        path: 'my-profile',
        data: { title: 'My Profile', userId: 'my' },
        component: UserProfilePageComponent,
      },
      {
        pathMatch: 'prefix',
        path: 'trip',
        data: { title: 'Trips' },
        children: [
          {
            path: 'new',
            data: { title: 'New Trip', openModal: true },
            component: TripSummaryComponent,
          },
          {
            path: 'id/:id',
            data: { title: 'Trip Summary', openModal: false },
            component: TripSummaryComponent,
          },
          {
            path: 'duplicate/:id',
            data: {
              title: 'Duplicate Trip',
              openModal: true,
              duplicateTrip: true,
            },
            component: TripSummaryComponent,
          },
        ],
      },
      {
        pathMatch: 'full',
        path: 'users',
        data: { title: 'Users' },
        component: PeoplePageComponent,
        canActivate: [IsAdminGuard],
      },
      {
        pathMatch: 'full',
        path: 'search',
        data: { title: 'Search' },
        component: SearchComponent,
      },
      {
        pathMatch: 'full',
        path: 'teams',
        data: { title: 'Teams' },
        component: UserGroupPageComponent,
      },
      {
        pathMatch: 'prefix',
        path: 'help',
        data: { title: 'Help' },
        children: [
          {
            pathMatch: 'full',
            path: '',
            data: { title: 'Help' },
            component: HelpComponent,
          },
          {
            pathMatch: 'prefix',
            path: 'info',
            data: { title: '' },
            children: [
              {
                data: { titleHandledInComponent: true },
                matcher: MultipleSlugMatcher,
                component: InfoComponent,
              },
              {
                path: '**',
                data: { title: 'Help/Info' },
                component: InfoComponent,
              },
            ],
          },
          {
            path: '**',
            data: { title: 'Help' },
            component: HelpComponent,
          },
        ],
      },
      {
        path: '**',
        data: { title: 'Home' },
        redirectTo: 'home',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    // Long-lived Observable.  Never Destroyed.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          let title = child.snapshot.data?.title;
          let ignoreTitle = child.snapshot.data?.titleHandledInComponent;

          if (ignoreTitle) {
            // Do Nothing.
          } else if (title) {
            this.titleService.setTitle(title + ' | CIAO - Check In And Out');
          } else {
            this.titleService.setTitle('CIAO - Check In And Out');
          }
        })
      )
      .subscribe();
  }
}
