<ciao-modal
  #filterModal
  id="filter-modal"
  [title]="'Filter By'"
  (result)="resetFilters()"
>
  <div modalBody *ngIf="filterModalInitialized">
    <ng-template *ngIf="includeMyUserGroupsCheckbox">
      <div id="roles-section">
        <div class="control-container">
          <ciao-form-field
            class="control-item"
            label="My User Groups"
            type="checkbox"
            [formControlInput]="filterModalGroup.get('myUserGroups')"
          ></ciao-form-field>
        </div>
      </div>
      <hr />
    </ng-template>
    <div id="roles-section" *ngIf="includeRolesFilters">
      <h4 class="section-header">Roles</h4>
      <div class="control-container">
        <ciao-form-field
          class="control-item"
          label="System Admin"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.system_admin')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          label="Region/Station Admin"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.region_admin')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          label="Forest/Lab Admin"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.forest_admin')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          label="Team Supervisor"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.supervisor')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          [label]="'Team Member'"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.team_member')"
        ></ciao-form-field>
      </div>
      <hr />
    </div>
    <div id="filter-scroll-box">
      <div id="region-section" *ngIf="filterShouldShowRegionStation">
        <h4 class="section-header">Region or Station(s)</h4>
        <ciao-form-field
          class="control-item"
          label="All"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('allChecks.regions')"
        ></ciao-form-field>
        <div class="control-container">
          <ng-template
            ngFor
            ngForTrackById
            let-region
            [ngForOf]="filterRegionList$ | async"
          >
            <ciao-form-field
              class="control-item"
              [formControlInput]="filterModalGroup.get('regions.' + region.id)"
              [label]="region.label"
              type="checkbox"
            ></ciao-form-field>
          </ng-template>
        </div>
      </div>
      <div id="forest-section" *ngIf="filterShouldShowForestLab">
        <ng-template
          ngFor
          ngForTrackById
          let-region
          [ngForOf]="filterRegionList$ | async"
        >
          <ng-container *ngIf="filterModalGroup.value.regions[region.id]">
            <hr />
            <h4 class="section-header">{{ region.label }}</h4>
            <ciao-form-field
              class="control-item"
              label="All"
              type="checkbox"
              [formControlInput]="
                filterModalGroup.get(['allChecks', region.id])
              "
            ></ciao-form-field>
            <div class="control-container">
              <ng-template
                ngFor
                ngForTrackById
                let-forest
                [ngForOf]="region.children"
              >
                <ciao-form-field
                  class="control-item"
                  [formControlInput]="
                    filterModalGroup.get(['forests', forest.id])
                  "
                  [label]="forest.label"
                  type="checkbox"
                ></ciao-form-field>
              </ng-template>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <div modalFooter>
    <div class="modal-footer-buttons">
      <button
        class="btn-action-light"
        (click)="clearFilters(); filterModal.close('')"
      >
        Clear Filters
      </button>
      <button
        class="btn-action-solid"
        (click)="applyFilters(); filterModal.close('')"
        [disabled]="!filterModalGroup?.dirty"
      >
        Apply Filters
      </button>
    </div>
  </div>
</ciao-modal>
