<ciao-modal
  #userModal
  [title]="modalTitle$ | async"
  [preventBackdropClosing]="true"
>
  <div modalNav>
    <div id="nav-tabs">
      <ul>
        <ng-container *ngFor="let tabName of tabs">
          <li
            [matTooltip]="arrowMessage"
            [matTooltipPosition]="tabToolTipPosition"
            [matTooltipDisabled]="!modalIsOpen || !isNavigationDisabled"
            [matTooltipShowDelay]="showDelay"
            [matTooltipClass]="{ 'green-tooltip': true }"
            [class.active]="tabName === currentTab"
            [class.nav-disabled]="isNavigationDisabled[tabName]"
          >
            <button
              class="semantic-button"
              (click)="currentTab = tabName"
              [disabled]="isNavigationDisabled[tabName]"
              [class.nav-disabled]="isNavigationDisabled[tabName]"
            >
              <div class="tab-name">{{ tabName }}</div>
              <span *ngIf="tabName === currentTab" class="triangle"> </span>
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div modalBody>
    <ciao-user-details-form
      *ngIf="currentTab === 'Details'"
      #currentForm
      [personId]="id"
      class="imSpecial"
    ></ciao-user-details-form>
    <!-- Other form components to be added here -->
    <ciao-user-contact-form
      class="imSpecial"
      #currentForm
      [personId]="id"
      *ngIf="currentTab === 'Contact'"
    ></ciao-user-contact-form>
  </div>
  <span modalFooter class="imSpecial" id="user-modal-footer">
    <div class="d-flex">
      <button
        class="btn-action-solid center"
        (click)="saveData()"
        [disabled]="!formIsValid || isSaving"
      >
        {{ saveButtonMessage }}
      </button>
      <button
        *ngIf="false && showDeleteButton && !(isCurrentUser$ | async)"
        class="btn-action-light center"
        (click)="openDeleteModal()"
      >
        Delete User
      </button>
    </div>
    <div class="form-navigation">
      <button
        [matTooltip]="arrowMessage"
        [matTooltipPosition]="arrowToolTipPosition"
        [matTooltipDisabled]="!isNavigationDisabled['Details']"
        [matTooltipClass]="{ 'green-tooltip': true }"
        [disabled]="isNavigationDisabled['Details'] || isNavLeftDisabled"
        class="btn-action-light icon borderless"
        (click)="prevTab()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button
        [matTooltip]="arrowMessage"
        [matTooltipPosition]="arrowToolTipPosition"
        [matTooltipDisabled]="!isNavigationDisabled['Contact']"
        [matTooltipClass]="{ 'green-tooltip': true }"
        [disabled]="isNavigationDisabled['Contact'] || isNavRightDisabled"
        class="btn-action-light icon borderless"
        (click)="nextTab()"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </span>
</ciao-modal>

<ciao-modal #deletePersonModal title="Confirm User Deletion">
  <div modalBody>
    <h2>
      Are you sure you want to delete <br />
      {{ personDisplayName$ | async }}?
    </h2>
  </div>
  <span modalFooter>
    <div class="d-flex">
      <button
        class="btn-action-light center"
        (click)="deletePersonModal.dismiss('cancel')"
      >
        Cancel
      </button>
      <button class="btn-action-solid center" (click)="deleteData()">
        Confirm Delete
      </button>
    </div>
  </span>
</ciao-modal>
