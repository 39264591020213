import {
  BooleanInput,
  NumberInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { TextService } from '~/services';

@Component({
  selector: 'ciao-form-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.less'],
})
export class FieldComponent<T = any>
  implements OnInit, AfterViewInit, OnDestroy
{
  private static idsInUse = {} as { [id: string]: FieldComponent };
  @Input() type:
    | 'input'
    | 'text'
    | 'phoneNumber'
    | 'satPhoneNumber'
    | 'number'
    | 'latlong'
    | 'textarea'
    | 'dropdown'
    | 'combobox'
    | 'multiselect'
    | 'checkbox'
    | 'radio'
    | 'toggle'
    | 'date'
    | 'dateRange'
    | 'datetime' = 'input';
  @Input() subtype: string;
  @Input() label: string;
  @Input() subtext: string;
  @Input() placeholder: string;
  @Input() toolTip: string;
  @Input() toolTipTitle?: string;
  @Input() required: BooleanInput;
  @Input() radioValue: string;

  /** Use requiredProxy to add the red required asterix to the label without having an error when this field is blank.  Useful when this input acts as a validation proxy for another FormControl */
  @Input() requiredProxy: BooleanInput;
  get _required() {
    return coerceBooleanProperty(this.required);
  }
  @Input() validators: ValidatorFn[];
  @Input() name: string;
  @Input() width: string;
  @Input() defaultValue: any = '';
  /** Angular's select compareWith attribute.  Only applies on multiselect or normal dropdown (will work with autocomplete dropdowns at a later date)  (https://angular.io/api/forms/SelectControlValueAccessor#customizing-option-selection) */
  @Input() compareWith: (c1: T, c2: T) => boolean = (c1, c2) => {
    if (c1 instanceof Object) {
      return c1 === c2;
    } else {
      return c1?.toString() === c2?.toString();
    }
  };
  /** Material's multiselect customTrigger attribute.  Only applies when {@link type}='multiselect' */
  @Input() customTrigger: (values: T[]) => string;
  @Input() formControlInput: AbstractControl;
  get formControlInputAsFormControl() {
    return this.formControlInput as UntypedFormControl;
  }
  /** HTML input min attribute.  Only applies when {@link type}='number' */
  @Input() min?: NumberInput;
  get _min() {
    return coerceNumberProperty(this.min);
  }
  /** HTML input max attribute.  Only applies when {@link type}='number' */
  @Input() max?: NumberInput;
  get _max() {
    return coerceNumberProperty(this.max);
  }
  /** HTML input step attribute.  Only applies when {@link type}='number' */
  @Input() step?: NumberInput;
  get _step() {
    return coerceNumberProperty(this.step);
  }
  // secretFormControl?: AbstractControl = new FormControl('');
  // get secretFormControlAsFormControl() {
  //   return this.secretFormControl as FormControl;
  // }
  @Input() selectOptions$: Observable<SelectOption<T>[]>;
  @Input() autocomplete?: BooleanInput = false;
  get _autocomplete() {
    return coerceBooleanProperty(this.autocomplete);
  }
  @Input() inputId: string;
  @Input() helpText: string;

  subscriptions = new Subscription();
  constructor(
    private textService: TextService,
    private el: ElementRef<HTMLDivElement>
  ) {}

  ngOnInit(): void {
    this.calculateId();
    if (!this.formControlInput) {
      this.formControlInput = new UntypedFormControl(this.defaultValue, {
        validators: this.validators,
      });
    }
    if (this.type === 'phoneNumber') {
      let sub1 = this.formControlInput.valueChanges
        .pipe(
          debounceTime(400),
          tap((value: string) => {
            let newVal = value?.replace(/\D/g, '') || '';
            let newStringVal = '';
            const zip = newVal.substring(0, 3);
            const middle = newVal.substring(3, 6);
            const last = newVal.substring(6, 10);

            if (newVal.length > 6) {
              newStringVal = `(${zip}) ${middle} - ${last}`;
            } else if (newVal.length > 3) {
              newStringVal = `(${zip}) ${middle}`;
            } else if (newVal.length > 0) {
              newStringVal = `(${zip})`;
            }
            if (newStringVal != value) {
              this.formControlInput.patchValue(newStringVal);
            }
          })
        )
        .subscribe();
      this.subscriptions.add(sub1);
    } else if (this.type === 'satPhoneNumber') {
      let sub2 = this.formControlInput.valueChanges
        .pipe(
          debounceTime(400),
          tap((value: string) => {
            let newVal = value?.replace(/\D/g, '') || '';
            let newStringVal = '';
            const chunks = [
              newVal.substring(0, 3),
              newVal.substring(3, 5),
              newVal.substring(5, 8),
              newVal.substring(8, 11),
              newVal.substring(11, 15),
            ];

            newStringVal = chunks.filter((x) => !!x).join('-');
            if (newStringVal != value) {
              this.formControlInput.patchValue(newStringVal);
            }
          })
        )
        .subscribe();
      this.subscriptions.add(sub2);
    } else if (this.type === 'datetime') {
      // No longer need SecretFormControl
    }
    // block scope
    {
      let testControl = new UntypedFormControl('');
      let validator = this.formControlInput.validator;
      if (validator) {
        let testErrors = validator(testControl);
        if (testErrors?.required) {
          this.required = true;
        }
      }
    }
    let fn = this.formControlInput.validator;
    let validators = fn ? [fn] : [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.type === 'phoneNumber') {
      validators.push(CellPhoneValidator);
    }
    if (this.type === 'satPhoneNumber') {
      validators.push(SatPhoneValidator);
    }
    this.formControlInput.setValidators(validators);
  }
  ngAfterViewInit(): void {
    this.el.nativeElement
      .querySelectorAll<HTMLInputElement>('input[aria-multiline]')
      .forEach((input) => {
        input.attributes.removeNamedItem('aria-multiline');
      });
  }
  ngOnDestroy(): void {
    Object.entries(FieldComponent.idsInUse).forEach(([id, value]) => {
      if (value === this) {
        delete FieldComponent.idsInUse[id];
      }
    });
    this.subscriptions.unsubscribe();
  }

  calculateId() {
    if (!this.inputId && !this.label) {
      throw new Error(
        'inputId or Label must be defined before calculating the id of the form field'
      );
    }
    let inputId = this.inputId;
    if (!inputId)
      inputId = 'form_field-' + this.label.toLowerCase().replace(/\W+/g, '_');
    if (FieldComponent.idsInUse[inputId]) {
      inputId += '_' + uuidv4();
    }
    FieldComponent.idsInUse[inputId] = this;
    this.inputId = inputId;
  }
}
const CellPhoneValidator: ValidatorFn = (control) => {
  if (!control.value) {
    return null;
  }
  if (control.value.replace(/\D/g, '').length === 10) {
    return null;
  } else {
    return {
      cellPhonePattern: 'Phone does not match 10-digit cell phone pattern',
    };
  }
};
const SatPhoneValidator: ValidatorFn = (control) => {
  if (!control.value) {
    return null;
  }
  if (control.value.replace(/\D/g, '').length === 15) {
    return null;
  } else {
    return {
      satPhonePattern:
        'Satellite Phone does not match 15-digit sat phone pattern',
    };
  }
};

type SelectOption<T = any> = {
  label: string;
  value: string | T;
  disabled?: boolean;
};
