<ng-container
  *ngIf="showAnnouncements && announcements?.[currentIndex] as announcement"
>
  <div class="bar-container">
    <span class="announcement-text">
      {{ announcement.text }}
    </span>
    <div class="controls">
      <button
        (click)="clickPrevious(true)"
        class="circle"
        [disabled]="currentIndex < 1"
      >
        <span class="screenreader">view previous announcement and pause</span>
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <button
        (click)="clickNext(true)"
        class="circle"
        [disabled]="currentIndex > announcements.length - 2"
      >
        <span class="screenreader">view next announcement and pause</span>
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
      <button (click)="closeAnnouncementBar()" class="circle close">
        <span class="screenreader">close announcements bar</span>
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>
</ng-container>
<div *ngIf="announcements.length > 0" class="white-bar"></div>

<button
  *ngIf="announcements.length > 0 && !showAnnouncements"
  class="booktab"
  (click)="openAnnouncementBar()"
>
  <span class="screenreader" aria-live="assertive">expand announcements</span>
  Notifications
</button>
