import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  catchError,
  delay,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { CiaoSharedModule } from '~app/components/shared/shared.module';

import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';
import { AuthService, PersonService } from '~app/services';
import { TooltipPosition } from '@angular/material/tooltip';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { UserModalFormComponent } from './type';
import { RolePermissionService } from '~app/services/role-permission.service';
import { ToastrService } from 'ngx-toastr';
import { UserDetailFormComponent } from './user-details-form/user-details-form.component';
import { UserContactFormComponent } from './user-contact-form/user-contact-form.component';

const Tabs = ['Details', 'Contact'] as const;
export type TabName = (typeof Tabs)[number];

@Component({
  standalone: true,
  imports: [
    CiaoSharedModule,
    AsyncPipe,
    NgIf,
    NgFor,
    UserDetailFormComponent,
    UserContactFormComponent,
  ],
  selector: 'ciao-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.less'],
})
export class UserModalComponent implements OnInit {
  readonly tabs = Tabs;
  readonly personId$ = new BehaviorSubject<string>(null);
  readonly person$ = this.personId$.pipe(
    filter((id) => !!id),
    switchMap((id) => this.personService.findById(id)),
    map((person) => {
      return person;
    }),
    shareReplay(1)
  );
  readonly personDisplayName$ = this.person$.pipe(
    map((person) => person.displayName),
    shareReplay(1)
  );
  readonly currentPersonId$ = this.authService.currentUser$.pipe(
    map((user) => user?.person?.id),
    shareReplay(1)
  );
  readonly isCurrentUser$ = combineLatest([
    this.currentPersonId$,
    this.personId$,
  ]).pipe(
    map(([currentPersonId, personId]) => currentPersonId === this.id),
    shareReplay(1)
  );
  readonly modalTitle$ = combineLatest([
    this.personId$,
    this.currentPersonId$,
  ]).pipe(
    switchMap(([personId, currentPersonId]) => {
      if (!personId) {
        return of('New User Profile');
      } else if (personId === currentPersonId) {
        return of('My Profile');
      } else {
        return this.personDisplayName$;
      }
    })
  );

  get isAdmin() {
    return this.rolePermissionService.isAdmin$;
  }

  get id() {
    return this.personId$.value;
  }
  @Input() set id(value: string) {
    this.personId$.next(value);
    this.cdr.detectChanges();
    if (this._currentTab) {
      this.currentForm.refreshPerson();
    }
  }
  @ViewChild('userModal') userModal: CiaoModalComponent;
  @ViewChild('currentForm') currentForm: UserModalFormComponent;

  @ViewChild('deletePersonModal') deletePersonModal: CiaoModalComponent;

  modalIsOpen: boolean;

  // Setting current tab to null so that the comp is destroyed before modal init
  private _currentTab: TabName = null;
  set currentTab(value: TabName) {
    if (Tabs.includes(value)) {
      this._currentTab = value;
    } else {
      this._currentTab = 'Details';
    }
    this.cdr.detectChanges();
    this.currentForm.refreshPerson();
  }
  get currentTab() {
    return this._currentTab;
  }

  //Tool tip on arrow hover
  get arrowMessage() {
    return `Select ${
      this.currentTab == 'Details' ? '"Update"' : '"Add"'
    } to continue.`;
  }

  get saveButtonMessage() {
    return this.id ? 'Update and Save' : 'Save User';
  }

  get isNavLeftDisabled() {
    return this.currentTab === 'Details';
  }

  get isNavRightDisabled() {
    return this.currentTab === 'Contact';
  }

  get isNavigationDisabled() {
    return {
      Details: false,
      Contact: !this.id,
    };
  }

  get showDeleteButton() {
    return this.currentTab == 'Details' && this.id && this.isAdmin;
  }

  get formIsValid() {
    return this.currentForm?.formGroup?.valid;
  }

  tabToolTipPosition: TooltipPosition = 'below';
  arrowToolTipPosition: TooltipPosition = 'above';
  showDelay = 0;

  isSaving = false;

  subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private personService: PersonService,
    private rolePermissionService: RolePermissionService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Make sure current tab is not null before trying to call refresh on child comps
    if (this._currentTab) {
      this.currentForm.refreshPerson();
    }
  }

  openModal() {
    this.isSaving = false;
    // Sett current tab to details on modal open child comp get re-initialised with new data
    this._currentTab = 'Details';
    let modal = this.userModal.openModal();
    modal?.result?.finally(() => {
      // Sett current tab to null on modal close to start cycle over again
      this._currentTab = null;
    });
  }

  closeEditModal() {
    this.userModal.close('back to view');
  }

  saveData() {
    if (this.isSaving) {
      console.warn('Attempting to save multiple times');
      return;
    }
    // disable the save button so user cant keep clicking it
    this.isSaving = true;
    this.currentForm
      .saveFormData()
      .pipe(
        tap(() => {
          this.userModal.close('user modal attempted to save');
        }),
        catchError((err, caught) => {
          console.error(err);
          // delay when the save button is able to be clicked again
          return of(null).pipe(delay(1000));
        }),
        tap(() => (this.isSaving = false))
      )
      .subscribe();
  }

  nextTab() {
    this.currentTab = {
      Details: 'Contact',
      Contact: 'Contact',
    }[this.currentTab] as TabName;
  }
  prevTab() {
    this.currentTab = {
      Details: 'Details',
      Contact: 'Details',
    }[this.currentTab] as TabName;
  }

  openDeleteModal() {
    this.deletePersonModal.openModal();
  }
  deleteData() {
    const sub = this.personService
      .deletePerson(this.personId$.value)
      .pipe(
        take(1),
        tap(() => {
          this.toastrService.success(
            'User was deleted successfully.',
            'Success'
          );
          this.closeEditModal();
        }),
        catchError((err) => {
          console.error(err);
          this.toastrService.error(
            `User was not deleted successfully. Error: ${err}`,
            'Error'
          );
          return of(null);
        }),
        tap(() => {
          this.deletePersonModal.close('closing delete modal');
        })
      )
      .subscribe();

    this.subscriptions.add(sub);
    return sub;
  }
}
