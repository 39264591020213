import { get } from 'lodash';

export function _checkNotNull(
  value,
  message = 'nulls are not allowed.  Pass allowNulls to allow sorting nulls'
) {
  if (value === undefined || value === null) {
    throw new Error(message);
  }
}
export function _checkNotObject(
  value,
  message = 'found an object instead of a string'
) {
  if (String(value).startsWith('[object ')) {
    throw new Error(message);
  }
}

/**
 * Returns a sorting function that sorts on a nested property of the object.
 * Sorts alphabetically
 *
 * @returns {function(T,T):(-1 | 0 | 1)}
 */
export function SimpleStringSortBy<T = any>(
  nestedKeys: string,
  descending = false
) {
  return (a: T, b: T) => {
    const order = descending ? -1 : 1;
    const valueA = String(get(a, nestedKeys));
    const valueB = String(get(b, nestedKeys));

    let val = 0;
    if (valueA < valueB) {
      val = 1 * order;
    } else if (valueA > valueB) {
      val = -1 * order;
    } else {
      val = 0;
    }
    return val;
  };
}

/**
 * Returns a sorting function that sorts on a nested property of the object.
 * Sorts numerically
 *
 * @returns {function(T,T):(-1 | 0 | 1)}
 */
export function SimpleNumericSortBy<T = any>(
  nestedKeys: string,
  descending = false
) {
  return (a: T, b: T) => {
    const order = descending ? -1 : 1;
    let valueA = parseFloat(get(a, nestedKeys));
    let valueB = parseFloat(get(b, nestedKeys));
    // If !allowNulls, undefined and null should error.
    // 0, false, empty string should all still pass

    return (valueA - valueB) * order;
  };
}
