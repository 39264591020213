<h1>My Profile</h1>
<form class="profile-form">
  <div class="row">
    <ciao-form-field
      label="First Name"
      [formControlInput]="formGroup.get('person.firstName')"
    ></ciao-form-field>
    <ciao-form-field
      label="Last Name"
      [formControlInput]="formGroup.get('person.lastName')"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      label="User Type"
      type="dropdown"
      [selectOptions$]="jobOptions$"
      [formControlInput]="formGroup.get('person.jobTitle')"
    ></ciao-form-field>
    <ciao-form-field
      label="Admin Roles"
      placeholder="No Roles Selected"
      [formControlInput]="formGroup.get('regionRoles')"
      [type]="'multiselect'"
      [selectOptions$]="AssignRegionRoleSelectOptions$"
      [compareWith]="AssignRegionRoleCompare"
      [customTrigger]="AssignRegionRoleCustomTriggerText"
    ></ciao-form-field>
    <ciao-form-field
      label="Default Supervisor"
      [formControlInput]="formGroup.get('defaultSupervisorId')"
      [toolTip]="infoText.defaultSupervisor"
      type="combobox"
      [selectOptions$]="defaultSupervisorSelectOptions$"
    ></ciao-form-field>
  </div>
</form>
<hr />
<h1>My Contact Info</h1>
<form class="contact-form">
  <h2 class="fullwidth">Email Addresses for Authentication <span>*</span></h2>
  <p class="fullwidth">
    A valid email is required usa a .GOV email for government device logins and
    your personal Login.gov email for personal device logins.
  </p>
  <fieldset>
    <ciao-form-field
      label="Primary Email"
      [formControlInput]="formGroup.get('users.0.email')"
    ></ciao-form-field>
    <ciao-form-field
      class="indent"
      type="checkbox"
      label="Email Notifications"
      [formControlInput]="
        formGroup.get('users.0.notificationOptions.defaultSet')
      "
    ></ciao-form-field>
  </fieldset>
  <fieldset>
    <ciao-form-field
      label="Secondary Email"
      [formControlInput]="formGroup.get('users.1.email')"
    ></ciao-form-field>
    <ciao-form-field
      class="indent"
      type="checkbox"
      label="Email Notifications"
      [formControlInput]="
        formGroup.get('users.1.notificationOptions.defaultSet')
      "
    ></ciao-form-field>
  </fieldset>

  <h2 class="fullwidth">My Cell Phone Numbers</h2>
  <fieldset>
    <ciao-form-field
      label="Government Cell Phone"
      [formControlInput]="formGroup.get('governmentPhone.phoneNumber')"
      [toolTip]="infoText.GovernmentDevice"
      type="phoneNumber"
    ></ciao-form-field>
    <div class="indent">
      <mat-radio-group
        name="deafultPhone"
        [formControl]="formGroup.controls.defaultPhone"
      >
        <mat-radio-button
          [value]="'government'"
          [disabled]="disableGovernmentPhoneRadio"
        >
          Default Phone
        </mat-radio-button>
      </mat-radio-group>
      <hr />
      <legend [ngClass]="{ disabled: disableGovernmentPhoneRadio }">
        Select Text Notifications
      </legend>
      <ng-container *ngFor="let option of textNotificationOptions">
        <ciao-form-field
          [label]="option.label"
          [formControlInput]="
            formGroup.get(['governmentPhone', 'smsNotifications', option.key])
          "
          type="checkbox"
        ></ciao-form-field>
      </ng-container>
    </div>
  </fieldset>
  <fieldset>
    <ciao-form-field
      label="Personal Cell Phone"
      [formControlInput]="formGroup.get('personalPhone.phoneNumber')"
      [toolTip]="infoText.Personal"
      type="phoneNumber"
    ></ciao-form-field>
    <div class="indent">
      <mat-radio-group
        name="deafultPhone"
        [formControl]="formGroup.controls.defaultPhone"
      >
        <mat-radio-button
          [value]="'personal'"
          [disabled]="disablePersonalPhoneRadio"
        >
          Default Phone
        </mat-radio-button>
      </mat-radio-group>

      <hr />
      <legend [ngClass]="{ disabled: disablePersonalPhoneRadio }">
        Select Text Notifications
      </legend>
      <ng-container *ngFor="let option of textNotificationOptions">
        <ciao-form-field
          [label]="option.label"
          [formControlInput]="
            formGroup.get(['personalPhone', 'smsNotifications', option.key])
          "
          type="checkbox"
        ></ciao-form-field>
      </ng-container>
    </div>
  </fieldset>
</form>
<div class="btn-container">
  <button
    class="btn-action-solid"
    [disabled]="!formGroup.valid || isSaving"
    (click)="updateProfile()"
  >
    Update User Profile
  </button>
</div>
